<template>
  <div>
    <v-app-bar
      elevation="0"
      dark
      class="dark-banner white--text"
      id="nav"
      dir="rtl"
    >
      <template>
        <v-app-bar-nav-icon
          @click="drawer = true"
          class="d-flex d-sm-none"
        ></v-app-bar-nav-icon>
        <img
          src="../../assets/PlatformLogo-04.svg"
          alt="forms-image"
          width="40px"
          height="auto"
          class="logoImag mb-2 mt-3"
          v-show="$vuetify.breakpoint.mdAndUp"
        />

        <span class="fa-13"> منصة مؤكد | الحقيقة من مصدرها الرسمي </span>
        <v-spacer></v-spacer>
        <span class="normal-font small-text">
          <span class="mx-1 mt-1 fa-12">{{ authenticated.email }} </span>
          <v-icon class="fa-14" small>mdi-account-circle-outline</v-icon>
        </span>
      </template>
    </v-app-bar>

    <!--Render this sidebar only when logged in-->
    <!-- Add a navigation bar -->
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-for="(item, i) in items" :key="i" :to="item.route">
            <v-icon small>{{ item.icon }}</v-icon>
            <v-list-item-content>
              <v-list-item-title class="ps-2">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  watch: {},
  name: "firstPage",
  data() {
    return {
      pathVar: localStorage.getItem("current-path"),
      drawer: false,
      tab: null,
      fixedVar: true,
      time: "",
      date: "",
      path: "Home",
      week: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      timerID: setInterval(this.updateTime, 1000),
      items: [],
      role: "user",
    };
  },
  created() {
    this.updateTime();
    this.roleCheck(this.role);
  },
  computed: {
    ...mapGetters(["authenticated"]),
  },

  methods: {
    roleCheck() {
      this.items = [
        {
          title: "الصفحة الرئيسية",
          icon: "mdi-home",
          route: "/client/dashboard",
          linkName: "Home",
        },

        {
          title: "مدير المنشورات",
          icon: "mdi-newspaper",
          route: "/client/post-manager",
          linkName: "Mailbox",
        },
        {
          title: "صندوق البريد",
          icon: "mdi-email",
          route: "/client/mail-inbox",
          linkName: "Mailbox",
        },

        {
          title: "التقارير",
          icon: "mdi-poll",
          route: "/client/report-center",
          linkName: "Report Center",
        },

        {
          title: "دليل المستخدم",
          icon: "mdi-video",
          route: "/client/user-guide",
          linkName: "User Guide",
        },
        {
          title: "الاعدادات",
          icon: "mdi-account-circle-outline",
          route: "/client/settings",
          linkName: "Account Setting",
        },
      ];
    },
    updateTime() {
      var cd = new Date();
      this.time =
        this.zeroPadding(cd.getHours(), 2) +
        ":" +
        this.zeroPadding(cd.getMinutes(), 2) +
        ":" +
        this.zeroPadding(cd.getSeconds(), 2);
      this.date =
        this.zeroPadding(cd.getFullYear(), 4) +
        "-" +
        this.zeroPadding(cd.getMonth() + 1, 2) +
        "-" +
        this.zeroPadding(cd.getDate(), 2) +
        " " +
        this.week[cd.getDay()];
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
  },
};
</script>

<style scoped></style>
