<template>
  <div>
    <v-dialog
      v-if="EditUserDialog && currentUser"
      :value="EditUserDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card dir="rtl">
        <v-toolbar light class="mb-5">
          <v-btn icon light @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تعديل تفاصيل المستخدم</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text class="red-panel" @click="confirmDelete"
              ><v-icon>mdi-delete-circle-outline</v-icon> حذف الحساب</v-btn
            >

            <v-btn text @click="closeDialog">إغلاق</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form ref="user_details">
              <v-row>
                <v-col cols="12" class="blue lighten-5 rounded">
                  <span class="d-block">
                    <v-icon>mdi-alert-circle-outline</v-icon>
                    الرجاء مليء جميع الحقول المطلوبة لغرض تسجيل البيانات ضمن
                    المنصة وباللغتين العربية والانكليزية
                  </span>
                </v-col>
                <v-col cols="12" md="12" lg="12" xl="12" xxl="12" class="">
                  <v-text-field
                    v-model="userData.name"
                    type="text"
                    placeholder="الاسم الكامل"
                    outlined
                    :rules="requiredRule"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="12" xl="12" xxl="12" class="">
                  <v-text-field
                    v-model="userData.email"
                    type="email"
                    placeholder="البريد الالكتروني"
                    outlined
                    :rules="requiredRule"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="12" xl="12" xxl="12" class="">
                  <v-select
                    v-model="userData.role"
                    :items="userRoles"
                    outlined
                    hide-details="auto"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12" lg="12" xl="12" xxl="12">
                  <v-text-field
                    v-model="userData.password"
                    type="password"
                    placeholder="كلمة السر الجديدة"
                    outlined
                    :rules="[...requiredRule, validatePasswordRule]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mx-auto text-right">
                  <SnackbarComponent
                    :show="snackbar.show"
                    :timeout="snackbar.timeout"
                    :type="snackbar.type"
                    :text="snackbar.text"
                    @update:show="updateSnackbar"
                    @close-snackbar="closeSnackbar"
                  />
                  <v-btn
                    :loading="loadingSpinner"
                    :disabled="loadingSpinner"
                    color="primary"
                    @click="editUserFunc"
                    ><v-icon>mdi-pencil-circle-outline</v-icon> تعديل
                    البيانات</v-btn
                  >
                  <v-btn color="primary" text @click="closeDialog">
                    إغلاق
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--Delete dialog confirmation-->
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card dir="rtl">
        <v-card-title class="bg-red"
          ><v-icon color="white" class="mx-2">mdi-delete-circle-outline</v-icon>
          تاكيد الحذف</v-card-title
        >
        <v-card-text class="py-4">هل انت متأكد من حذف المستخدم؟</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false"
            >اغلاق</v-btn
          >
          <v-btn class="bg-red px-2" text @click="deleteUserFunc"> حذف</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

export default {
  props: ["EditUserDialog", "currentUser"],
  components: {
    SnackbarComponent,
  },
  computed: {
    ...mapGetters(["allCategories", "userRoles"]),
  },
  data() {
    return {
      deleteDialog: false,
      userData: Object.assign({}, this.currentUser),
      requiredRule: [(v) => !!v || "This field is required"],
      validatePasswordRule: [
        (v) => v.length >= 6 || "Password must be at least 6 characters long",
      ],
      userRoles: [
        { text: "administrator", value: "administrator" },
        { text: "editor", value: "editor" },
      ],
      loadingSpinner: false,
      snackbar: {
        show: false,
        timeout: 6000,
        type: "info",
        text: "",
      },
    };
  },

  watch: {
    currentUser(newVal) {
      this.userData = Object.assign({}, newVal);
    },
  },

  methods: {
    ...mapActions(["fetchUserRoles", "editUser", "deleteUser"]),

    closeDialog() {
      this.$emit("editUserDialogClose", false);
    },

    editUserFunc() {
      if (!this.$refs.user_details.validate()) return;
      this.loadingSpinner = true;

      const userForm = new FormData();
      userForm.append("id", this.userData.id);
      userForm.append("name", this.userData.name);
      userForm.append("email", this.userData.email);
      userForm.append("role", this.userData.role);

      if (this.userData.password) {
        userForm.append("password", this.userData.password);
      }

      userForm.append("_method", "PATCH");

      this.loadingSpinner = true;
      this.editUser(userForm)
        .then(() => {
          this.snackbar.show = true;
          this.snackbar.text = "تم تعديل المستخدم بنجاح";
          this.snackbar.type = "success";
          setTimeout(() => {
            this.snackbar.show = false;
            this.loadingSpinner = false;
            this.closeDialog();
          }, 3000);
        })
        .catch((err) => {
          this.loadingSpinner = false;
          this.snackbar.show = true;
          this.snackbar.text = "حدث خطأ: " + err;
          this.snackbar.type = "error";
        });
    },

    updateSnackbar(value) {
      this.snackbar.show = value;
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },

    confirmDelete() {
      this.deleteDialog = true;
    },
    async deleteUserFunc() {
      this.deleteDialog = false;
      try {
        this.loadingSpinner = true;
        await this.deleteUser(this.userData);
        this.snackbar.show = true;
        this.snackbar.text = "The user has been deleted successfully";
        this.snackbar.type = "success";
        setTimeout(() => {
          this.snackbar.show = false;
          this.loadingSpinner = false;
          this.closeDialog();
        }, 3000);
      } catch (err) {
        this.loadingSpinner = false;
        this.snackbar.show = true;
        this.snackbar.text = "An error occurred: " + err;
        this.snackbar.type = "error";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.image-container {
  position: relative;
}

.remove-btn {
  top: 0px;
  right: 0px;
  border: 1px solid #000 !important;
}

.bordered {
  border: 1px solid #000;
}
</style>
