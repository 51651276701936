<template>
  <div v-if="addArticleDialog" class="text-center" dir="rtl">
    <v-dialog
      fullscreen
      :value="addArticleDialog"
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card v-click-outside="handleClickOutside">
        <v-toolbar light class="mb-5" dir="rtl">
          <v-btn icon light @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="me-5"> اضافة منشور </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="closeDialog"> Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form ref="article_form" dir="rtl">
              <v-row>
                <v-col cols="12" class="blue lighten-5 rounded mb-1">
                  <span class="d-block"
                    ><v-icon>mdi-alert-circle-outline</v-icon> الرجاء مليء جميع
                    الحقول المطلوبة لغرض تسجيل البيانات ضمن المنصة وباللغتين
                    العربية والانكليزية
                  </span>
                </v-col>
                <v-col cols="12" class="">
                  <v-row>
                    <v-col cols="12" md="4" lg="4" xl="4" xxl="4" class="pb-0">
                      <v-autocomplete
                        v-model="category_id"
                        :items="allFlattenedCategories"
                        item-value="id"
                        outlined
                        required
                        clearable
                        :rules="requiredRule"
                        :item-text="textFormat"
                        placeholder="التصنيف"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      lg="4"
                      xl="4"
                      xxl="4"
                      class="pb-0"
                      dir="ltr"
                    >
                      <v-switch
                        hide-details
                        inset
                        color="success"
                        :model-value="approved"
                        :label="processApprovedLabel(approved)"
                        @change="toggleApproval"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="selectedTag"
                        :items="availableTags"
                        :item-text="availableTags.text"
                        :item-value="availableTags.value"
                        placeholder="اختيار فئة المنشور"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-btn
                        @click="addTag"
                        elevation="0"
                        class="blue lighten-1 white--text my-auto"
                        >Add Tag</v-btn
                      >
                    </v-col>

                    <div class="selected-tags">
                      <v-chip
                        dir="ltr"
                        v-for="(tag, index) in tags"
                        :key="index"
                        close
                        @click:close="removeTag(index)"
                        class="blue white--text mx-1"
                      >
                        {{ tag }}
                      </v-chip>
                    </div>
                  </v-row>
                </v-col>

                <v-col cols="6" class="py-0">
                  <v-col cols="12" class="white mb-2">
                    <span class="d-block fa-16"
                      ><v-icon>mdi-alert-circle-outline</v-icon> البيانات باللغة
                      العربية
                    </span>
                  </v-col>
                  <v-col cols="12" md="12" lg="12" xl="12" xxl="12" class="">
                    <v-text-field
                      v-model="title_arabic"
                      type="text"
                      placeholder="عنوان المنشور"
                      outlined
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" lg="12" xl="12" xxl="12">
                    <v-textarea
                      v-model="content_arabic"
                      placeholder="تفاصيل المنشور"
                      outlined
                      :rules="requiredRule"
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-col>

                <!--English Data Section-->
                <v-col cols="6" dir="ltr" class="py-0">
                  <v-col cols="12" class="white mb-2">
                    <span class="d-block fa-16"
                      ><v-icon>mdi-alert-circle-outline</v-icon>
                      Post data in English
                    </span>
                  </v-col>
                  <v-col cols="12" md="12" lg="12" xl="12" xxl="12" class="">
                    <v-text-field
                      v-model="title_en"
                      type="text"
                      placeholder="Title"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" lg="12" xl="12" xxl="12">
                    <v-textarea
                      v-model="content_en"
                      placeholder="Post Details"
                      outlined
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="pb-4 mb-5 mt-5 blue lighten-5 rounded">
                  <h3>صور المنشورات</h3>
                </v-col>

                <v-col v-for="(image, index) in images" :key="index" cols="12">
                  <v-row>
                    <v-col cols="4">
                      <v-file-input
                        v-model="image.file"
                        label="Choose an image"
                        outlined
                        accept="image/*"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="image.type"
                        :items="['original', 'fake']"
                        outlined
                        label="Image Type"
                      ></v-select>
                    </v-col>
                    <v-col cols="4" class="mt-3">
                      <v-btn
                        @click="removeImage(index)"
                        color="error"
                        elevation="0"
                        class="lighten-2 small-text"
                      >
                        <v-icon>mdi-delete</v-icon> حذف صورة
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    @click="addImage"
                    class="blue lighten-1 white--text"
                    elevation="0"
                  >
                    <v-icon>mdi-plus</v-icon> اضافة صورة
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mx-auto">
                  <SnackbarComponent
                    :show="snackbar.show"
                    :timeout="snackbar.timeout"
                    :type="snackbar.type"
                    :text="snackbar.text"
                    @update:show="updateSnackbar"
                    @close-snackbar="closeSnackbar"
                  />
                  <v-btn
                    :loading="loadingSpinner"
                    :disabled="loadingSpinner"
                    class="blue lighten-0 white--text"
                    elevation="0"
                    @click="registerArticle()"
                    ><v-icon>mdi-database</v-icon> تسحيل المنشور</v-btn
                  >
                  <v-btn color="primary" text @click="closeDialog">
                    اغلاق
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

export default {
  label: "AddArticle",
  props: ["addArticleDialog"],

  components: {
    SnackbarComponent,
  },
  computed: {
    ...mapGetters(["allCategories", "allFlattenedCategories", "allBrands"]),
  },
  data() {
    return {
      selectedTag: null,
      approved: false,
      approvedVal: null,
      title_arabic: null,
      category_id: null,
      title_en: null,
      content_arabicabic: null,
      content_en: null,
      status: null,
      images: [
        {
          file: null,
          type: "original", // or 'fake'
        },
      ],
      articleData: new FormData(),
      requiredRule: [(v) => !!v || "This field is required"],
      requiredRule2: [(v) => v.length > 0 || "This field is required"],
      loadingSpinner: false,
      tags: [],
      availableTags: [
        { text: "وثائق", value: "documents" },
        { text: "اخبار", value: "news" },
        { text: "بيانات", value: "data" },
        { text: "صور", value: "pictures" },
        { text: "فيديوهات", value: "videos" },
        { text: "منشورات", value: "publications" },
        { text: "بحوث", value: "research" },
        { text: "مقالات", value: "articles" },
      ],

      snackbar: {
        show: false,
        timeout: 6000,
        type: "info",
        text: "",
      },
    };
  },

  created() {
    this.fetchCategories();
    this.fetchFlattenedCategories();
  },
  methods: {
    ...mapActions([
      "addArticleFunc",
      "fetchCategories",
      "fetchFlattenedCategories",
    ]),

    addTag() {
      if (this.selectedTag && !this.tags.includes(this.selectedTag)) {
        this.tags.push(this.selectedTag);
      }
    },

    removeTag(index) {
      this.tags.splice(index, 1);
    },

    addImage() {
      this.images.push({
        file: null,
        type: "approved",
      });
    },

    removeImage(index) {
      this.images.splice(index, 1);
    },

    closeDialog() {
      this.$emit("addArticleDialogClose", false);
    },
    handleClickOutside() {},

    registerArticle() {
      if (!this.$refs.article_form.validate()) return;
      this.loadingSpinner = true;
      this.articleData.append("category_id", this.category_id);
      this.articleData.append("title_arabic", this.title_arabic);
      this.articleData.append("content_arabic", this.content_arabic);

      if (this.approved == true) {
        this.approvedVal = "published";
      } else {
        this.approvedVal = "archived";
      }
      this.articleData.append("status", this.approvedVal);
      // Append English fields if they contain a value
      if (this.title_en) {
        this.articleData.append("title_english", this.title_en);
      }
      if (this.content_en) {
        this.articleData.append("content_english", this.content_en);
      }
      for (let i = 0; i < this.images.length; i++) {
        this.articleData.append("images[" + i + "][file]", this.images[i].file);
        this.articleData.append("images[" + i + "][type]", this.images[i].type);
      }
      for (let i = 0; i < this.tags.length; i++) {
        this.articleData.append("tags[" + i + "]", this.tags[i]);
      }

      this.addArticleFunc(this.articleData)
        .then(() => {
          this.snackbar.show = true;
          this.snackbar.text = "تم تسجيل المنشور بنجاح";
          this.snackbar.type = "success";
          setTimeout(() => {
            this.snackbar.show = false;
            this.loadingSpinner = false;
            this.$refs.article_form.reset();
            this.tags = [];
            this.closeDialog();
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          this.loadingSpinner = false;
          this.snackbar.show = true;
          this.snackbar.text = "An Error occured:" + err;
          this.snackbar.type = "error";
        });
    },
    textFormat(item) {
      if (item.name_arabic) {
        if (item.userdirectory) return item.name_arabic;
        else return item.name_arabic;
      }
    },
    textFormat2(item) {
      return item.category_name_en;
    },
    processApprovedLabel(approved) {
      return approved ? "فعال" : "غير فعال";
    },
    toggleApproval() {
      this.approved = !this.approved;
    },
    showSnackbar() {
      this.snackbar.show = true;
    },
    updateSnackbar(value) {
      this.snackbar.show = value;
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
.selected-tags {
  margin-top: 10px;
}
.custom-chip {
  background-color: blue !important;
  color: white;
  padding: 5px 10px; /* Adjust the padding to expand the space between the tag and the close button */
}
</style>
