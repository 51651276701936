import axios from "@/axios";
import store from "../index";

const state = {
  token: "",
  userInfo: {},
};
const getters = {
  authenticated: (state) => state.token && state.userInfo,
  getUser: (state) => state.userInfo,
  isLoggedIn: (state) => (state.token ? true : false),
  getUserInfo: (state) => state.userInfo,
  getRole: () => store.getters["authenticated"].role,
};
const actions = {
  async loginFun({ dispatch }, loginInfo) {
    try {
      const response = await axios.post("/authorization/login", loginInfo);
      if (response.data.token) {
        return dispatch("attempt", response.data.token);
      }
      return response.data;
    } catch (error) {
      console.error("Error during login:", error);
      throw error;
    }
  },

  async attempt({ commit, state }, token) {
    if (token) commit("setToken", token);

    if (!state.token) return;
    try {
      const response = await axios.post("/authorization/me");
      localStorage.setItem("userInfo", JSON.stringify(response.data));
      commit("setUser", response.data);
      return "success";
    } catch {
      console.log("err");
      commit("setToken", null);
      commit("setUser", null);
    }
  },
  signout({ commit }) {
    return axios.post("/authorization/logout").then(() => {
      commit("setToken", null);
      commit("setUser", null);
    });
  },
};
const mutations = {
  setToken: (state, user_token) => (state.token = user_token),
  setUser: (state, userInfo) => (state.userInfo = userInfo),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
