<template>
  <div class="home-page small-text">
    <v-card-text class="pa-0 mb-4"
      >جميع التصنيفات
      <v-btn
        color="blue"
        elevation="0"
        class="small-text white--text me-5"
        @click="addInstituteDialog = true"
      >
        <v-icon right dark class="mx-2"> mdi-plus-circle-outline </v-icon>
        اضافة مؤسسة جديدة
      </v-btn></v-card-text
    >

    <v-card elevation="0">
      <v-card-text>
        <v-data-table
          align-center
          :headers="headers"
          :items="allInstitutes"
          hide-default-footer
          fixed-header
          :options="{ itemsPerPage: -1 }"
        >
          <template #[`item.logo`]="{ item }">
            <div class="circular-image-container my-3">
              <!-- Logo -->

              <img :src="item.logo" class="logo" />
            </div>
          </template>
          <template #[`item.options`]="{ item }">
            <v-btn
              elevation="0"
              class="blue small-text white--text lighten-2"
              @click="showInstituteDetails(item)"
              ><v-icon small class="me-1">mdi-file-edit </v-icon> تفاصيل</v-btn
            >
            <v-btn
              v-if="getRole == 'administrator' || getRole == 'developer'"
              elevation="0"
              class="red mx-2 small-text white--text lighten-2"
              @click="deleteInstitute(item)"
              ><v-icon small class="me-1">mdi-cancel </v-icon> حذف</v-btn
            >
          </template>
        </v-data-table>
        <!-- pagination -->
      </v-card-text>
    </v-card>

    <AddInstituteDialog
      :addInstituteDialog="addInstituteDialog"
      @addInstituteDataClose="addInstituteDialog = $event"
    />

    <EditInstituteDialog
      :EditInstituteDialog="EditInstituteDialog"
      :currentInstitute="instituteData"
      @editInstituteDialogClose="EditInstituteDialog = $event"
    />

    <DeleteInstituteDialog
      :DeleteInstituteDialog="DeleteInstituteDialog"
      :currentInstitute="instituteData"
      @DeleteInstituteDialogClose="DeleteInstituteDialog = $event"
    />

    <loading :isLoading="loading"></loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "@/components/LoadingScreen.vue";
import AddInstituteDialog from "@/components/control/institute/AddInstituteDialog.vue";
import EditInstituteDialog from "@/components/control/institute/InstituteDetailsDialog.vue";
import DeleteInstituteDialog from "@/components/control/institute/DeleteInstitute.vue";

export default {
  name: "InstitutePage",
  components: {
    AddInstituteDialog,
    EditInstituteDialog,
    DeleteInstituteDialog,
    Loading,
  },
  data() {
    return {
      loading: false,

      addInstituteDialog: false,
      headers: [
        { text: "اسم المؤسسة", value: "name_arabic" },
        { text: "اسم المؤسسة بالانجليزية", value: "name_english" },
        { text: "logo", value: "logo" },
        { text: "Options", value: "options" },
      ],
      EditInstituteDialog: false,
      DeleteInstituteDialog: false,
      instituteData: null,
      disablePagination: false,
      search: null,
      info: {
        keyword: null,
      },
      interval: null,
    };
  },
  computed: {
    ...mapGetters(["allInstitutes", "getRole", "getUser"]),
  },

  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.info.keyword = this.search;
        this.loading = true;
        this.fetchInstitutes(this.info)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },

  created() {
    this.loading = true;
    this.fetchInstitutes(this.info)
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions(["fetchInstitutes"]),
    searchInTable() {
      this.info.keyword = this.search;
      this.loading = true;
      this.fetchInstitutes(this.info)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    showInstituteDetails(item) {
      this.instituteData = item;
      this.EditInstituteDialog = true;
    },
    deleteInstitute(item) {
      this.instituteData = item;
      this.DeleteInstituteDialog = true;
    },
  },
};
</script>
