<template>
  <div class="home-page" dir="rtl">
    <v-container fluid class="py-5">
      <span class="fa-16">معلومات الحساب</span>
      <v-row class="text-right px-6 mt-5 fa-16">
        <v-col cols="12">
          <span> الاسم</span>

          <div class="fa-16">{{ getUserInfo.name }}</div>
        </v-col>

        <v-col cols="12">
          <span> البريد الالكتروني</span>

          <div class="fa-16">{{ getUserInfo.email }}</div>
        </v-col>
        <v-col cols="12">
          <span> نوع الحساب</span>
          <div class="fa-16">{{ getUserInfo.role }}</div>
        </v-col>

        <v-col cols="12" v-if="getUserInfo.created_at">
          <span> Account creation date:</span>
          <div class="fa-16">
            {{ getUserInfo.created_at }}
          </div>
        </v-col>

        <v-col cols="12" class="pt-4 text-right">
          <ChangePassword />
        </v-col>
      </v-row>
    </v-container>
    <loading :isLoading="loading"></loading>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChangePassword from "@/components/control/ChangePassword";
import Loading from "@/components/LoadingScreen.vue";

export default {
  name: "user-info",
  components: { ChangePassword, Loading },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    getback: function () {
      history.back();
    },
  },
  computed: {
    ...mapGetters(["authenticated", "getUserInfo"]),
  },

  watch: {
    // Watch for changes in the getUserInfo computed property
    getUserInfo: {
      handler() {
        // Once getUserInfo is available, set loading to false
        this.loading = false;
      },
      immediate: true, // Trigger the watch immediately
    },
  },

  created() {},
};
</script>

<style scoped lang="scss">
$accentColor: #2196f3;
$textColor: #fff;
$fcolor: rgb(100, 20, 80);
$bcolor: black;
$bgcolor: white;
$bgimage_path: "../assets/imgs/leftbackground.png";
$fontname: "JF Flat";

.userinfo {
  border: none;
  border-radius: 20px;
  vertical-align: middle;
  text-align: center;
  direction: rtl;
  font-family: $fontname;
}

.texttitles {
  font-size: large;
}

h2 {
  text-align: center;
}
</style>
