<template>
  <div class="home-page small-text">
    <v-card-text class="pa-0 mb-4"
      >جميع التصنيفات
      <v-btn
        color="blue"
        elevation="0"
        class="small-text white--text me-5"
        @click="addCategoryDialog = true"
      >
        <v-icon right dark class="mx-2"> mdi-plus-circle-outline </v-icon>
        اضافة تصنيف جديد
      </v-btn></v-card-text
    >

    <v-card elevation="0">
      <v-card-text>
        <v-data-table
          align-center
          :headers="headers"
          :items="allCategories"
          hide-default-footer
          fixed-header
          :options="{ itemsPerPage: -1 }"
        >
          <template #[`item.children`]="{ item }">
            <div
              v-for="child in item.children"
              :key="child.id"
              class="d-inline"
            >
              <span class="grey lighten-3 rounded mx-2 px-3 py-1">
                {{ child.name_arabic }}
              </span>
            </div>
          </template>

          <template #[`item.options`]="{ item }">
            <v-btn
              elevation="0"
              class="blue small-text white--text lighten-2"
              @click="showCategoryDetails(item)"
              ><v-icon small class="me-1">mdi-file-edit </v-icon> تفاصيل</v-btn
            >
            <v-btn
              elevation="0"
              class="red mx-2 small-text white--text lighten-2"
              @click="deleteCategory(item)"
              ><v-icon small class="me-1">mdi-cancel </v-icon> حذف</v-btn
            >
          </template>
        </v-data-table>
        <!-- pagination -->
      </v-card-text>
    </v-card>

    <AddCategoryDialog
      :addCategoryDialog="addCategoryDialog"
      @addCategoryDataClose="addCategoryDialog = $event"
    />

    <EditCategoryDialog
      :EditCategoryDialog="EditCategoryDialog"
      :currentCategory="productData"
      @editCategoryDialogClose="EditCategoryDialog = $event"
    />

    <DeleteCategoryDialog
      :DeleteCategoryDialog="DeleteCategoryDialog"
      :currentCategory="productData"
      @DeleteCategoryDialogClose="DeleteCategoryDialog = $event"
    />

    <loading :isLoading="loading"></loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "@/components/LoadingScreen.vue";
import AddCategoryDialog from "@/components/control/category/AddCategoryDialog.vue";
import EditCategoryDialog from "@/components/control/category/CategoryDetailsDialog.vue";
import DeleteCategoryDialog from "@/components/control/category/DeleteCategory.vue";

export default {
  name: "CategoryPage",
  components: {
    AddCategoryDialog,
    EditCategoryDialog,
    DeleteCategoryDialog,
    Loading,
  },
  data() {
    return {
      loading: false,

      addCategoryDialog: false,
      headers: [
        { text: "التصنيف الرئيسي", value: "name_arabic" },
        { text: "التصنيفات الفرعية", value: "children" },

        { text: "Options", value: "options" },
      ],
      EditCategoryDialog: false,
      DeleteCategoryDialog: false,
      productData: null,
      disablePagination: false,
      search: null,
      info: {
        page: 1,
        keyword: null,
      },
      interval: null,
    };
  },
  computed: {
    ...mapGetters(["allCategories", "getRole", "getUser"]),
  },

  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.info.page = newVal;
        this.info.keyword = this.search;
        this.disablePagination = true;
        this.loading = true;
        this.fetchCategories(this.info)
          .then(() => {
            this.loading = false;
            this.disablePagination = false;
          })
          .catch(() => {
            this.loading = false;
            this.disablePagination = false;
          });
      }
    },
  },

  created() {
    this.disablePagination = true;
    this.loading = true;
    this.fetchCategories(this.info)
      .then(() => {
        this.loading = false;
        this.disablePagination = false;
      })
      .catch(() => {
        this.loading = false;
        this.disablePagination = false;
      });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions(["fetchCategories"]),
    searchInTable() {
      this.info.page = 1;
      this.info.keyword = this.search;
      this.loading = true;
      this.fetchCategories(this.info)
        .then(() => {
          this.loading = false;
          this.disablePagination = false;
        })
        .catch(() => {
          this.loading = false;
          this.disablePagination = false;
        });
    },

    showCategoryDetails(item) {
      this.productData = item;
      this.EditCategoryDialog = true;
    },
    deleteCategory(item) {
      this.productData = item;
      this.DeleteCategoryDialog = true;
    },
  },
};
</script>
