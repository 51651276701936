<template>
  <v-dialog
    v-if="EditInstituteDialog && currentInstitute"
    :value="EditInstituteDialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
    @click:outside="handleClickOutside"
    dir="rtl"
  >
    <v-card v-click-outside="handleClickOutside" dir="rtl">
      <v-toolbar light class="mb-5">
        <v-btn icon light @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> تفاصيل المؤسسات </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text @click="closeDialog"> Close</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-form ref="institute_form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="instituteData.name_arabic"
                  outlined
                  required
                  clearable
                  :rules="requiredRule"
                  :item-text="textFormat"
                  placeholder="الاسم باللغة العربية"
                  label="الاسم باللغة العربية"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="instituteData.name_english"
                  outlined
                  required
                  clearable
                  :rules="requiredRule"
                  :item-text="textFormat"
                  placeholder="Institute in English"
                  label="Institute in English"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="instituteData.link"
                  outlined
                  required
                  clearable
                  :item-text="textFormat"
                  placeholder="رابط المؤسسة"
                  label="رابط المؤسسة"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-img
                  width="80%"
                  height="auto"
                  :src="instituteData.logo"
                  class="mx-auto"
                ></v-img>
              </v-col>

              <v-col cols="12">
                <v-file-input
                  v-model="new_image"
                  label="Choose an image to edit the original image"
                  outlined
                  accept="image/*"
                ></v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="mx-auto">
                <SnackbarComponent
                  :show="snackbar.show"
                  :timeout="snackbar.timeout"
                  :type="snackbar.type"
                  :text="snackbar.text"
                  @update:show="updateSnackbar"
                  @close-snackbar="closeSnackbar"
                />
                <v-btn
                  :loading="loadingSpinner"
                  :disabled="loadingSpinner"
                  color="primary"
                  @click="saveInstitute()"
                  >Edit data</v-btn
                >
                <v-btn color="primary" text @click="closeDialog"> Close </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

export default {
  label: "EditProductDetails",

  props: ["EditInstituteDialog", "currentInstitute"],
  components: {
    SnackbarComponent,
  },
  computed: {},
  data() {
    return {
      instituteData: Object.assign({}, this.currentInstitute),
      requiredRule: [(v) => !!v || "This field is required"],
      requiredRule2: [(v) => v.length > 0 || "This field is required"],
      slugRules: [
        (v) => !!v || "Slug is required",
        (v) =>
          /^[a-zA-Z0-9\-]+$/.test(v) ||
          "Slug must contain only English letters, numbers, and hyphens",
      ],
      new_image: null,
      loadingSpinner: false,
      alrtState: false,
      alrtText: null,
      snackbar: {
        show: false,
        timeout: 6000,
        type: "info",
        text: "",
      },
    };
  },

  watch: {
    currentInstitute(newVal) {
      this.instituteData = Object.assign({}, newVal);
    },
  },

  created() {},
  methods: {
    ...mapActions(["editInstituteFunc"]),

    closeDialog() {
      this.$emit("editInstituteDialogClose", false);
    },
    handleClickOutside() {},

    // Create an array of promises for each child edit request
    async saveInstitute() {
      if (!this.$refs.institute_form.validate()) return;
      this.loadingSpinner = true;

      this.loadingSpinner = true;
      let formData = new FormData();

      // Append each key-value pair of articleData to formData, excluding 'logo' if needed
      for (let key in this.instituteData) {
        if (this.instituteData.hasOwnProperty(key) && key !== "logo") {
          // Exclude 'logo' field
          formData.append(key, this.instituteData[key]);
        }
      }

      // Append new images
      if (this.new_image != null) {
        formData.append(`logo`, this.new_image);
      }
      formData.append("_method", "PATCH");

      this.editInstituteFunc(formData)
        .then(() => {
          this.snackbar.show = true;
          this.snackbar.text = "The institute has been edited successfully";
          this.snackbar.type = "success";
          setTimeout(() => {
            this.snackbar.show = false;
            this.loadingSpinner = false;
            this.closeDialog();
          }, 3000);
        })
        .catch((err) => {
          this.loadingSpinner = false;
          this.snackbar.show = true;
          this.snackbar.text = "An Error occured:" + err;
          this.snackbar.type = "error";
        });
    },

    textFormat(item) {
      if (item.name) {
        if (item.userdirectory) return item.name;
        else return item.name;
      }
    },

    showSnackbar() {
      this.snackbar.show = true;
    },
    updateSnackbar(value) {
      this.snackbar.show = value;
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
