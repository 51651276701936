<!-- Loading.vue -->
<template>
  <div v-if="isLoading" class="loading-container">
    <!-- Your loading spinner or message goes here -->
    <v-progress-circular
      indeterminate
      color="primary"
      class="loading-spinner fa-20"
    ></v-progress-circular>
    <h2 class="mt-4">الرجاء الانتظار...</h2>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
  },
  data: function () {
    return {};
  },
};
</script>

<style scoped>
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column; /* Arrange items in a column */
  justify-content: center;
  align-items: center;
  z-index: 999; /* Make sure it's above other elements */
}

.loading-image {
  width: 100%;
}

.loading-title,
.loading-subtitle {
  margin: 0; /* Remove default margin */
}
.loading-spinner {
  margin-top: 10px; /* Adjust the margin as needed */
}
</style>
