<template>
  <div v-if="addInstituteDialog" class="text-center">
    <v-dialog
      width="600px"
      :value="addInstituteDialog"
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card v-click-outside="handleClickOutside" dir="rtl">
        <v-toolbar light class="mb-5">
          <v-btn icon light @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> تفاصيل المؤسسات </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="closeDialog"> Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form ref="institute_form">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="name_arabic"
                    outlined
                    required
                    clearable
                    :rules="requiredRule"
                    :item-text="textFormat"
                    placeholder="الاسم باللغة العربية"
                    label="الاسم باللغة العربية"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="name_english"
                    outlined
                    required
                    clearable
                    :rules="requiredRule"
                    :item-text="textFormat"
                    placeholder="Institute in English"
                    label="Institute in English"
                  >
                  </v-text-field>
                </v-col>

                <v-row dir="ltr" class="px-2">
                  <v-col cols="2">
                    <span class="my-auto fa-20">https://</span>
                  </v-col>

                  <v-col cols="10">
                    <v-text-field
                      v-model="link"
                      outlined
                      required
                      clearable
                      :item-text="textFormat"
                      placeholder="رابط المؤسسة"
                      label="رابط المؤسسة"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-col cols="12">
                  <v-file-input
                    v-model="new_image"
                    label="Choose an image "
                    outlined
                    accept="image/*"
                  ></v-file-input>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="mx-auto">
                  <SnackbarComponent
                    :show="snackbar.show"
                    :timeout="snackbar.timeout"
                    :type="snackbar.type"
                    :text="snackbar.text"
                    @update:show="updateSnackbar"
                    @close-snackbar="closeSnackbar"
                  />
                  <v-btn
                    :loading="loadingSpinner"
                    :disabled="loadingSpinner"
                    color="primary"
                    @click="saveInstitute()"
                    >Add Institute</v-btn
                  >
                  <v-btn color="primary" text @click="closeDialog">
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

export default {
  label: "AddInstitute",
  props: ["addInstituteDialog"],
  components: {
    SnackbarComponent,
  },
  computed: {},
  data() {
    return {
      category: null,
      name_arabic: null,
      name_english: null,
      new_image: null,
      link: null,
      InstituteData: new FormData(),
      requiredRule: [(v) => !!v || "This field is required"],
      requiredRule2: [(v) => v.length > 0 || "This field is required"],
      slugRules: [
        (v) => !!v || "Slug is required",
        (v) =>
          /^[a-zA-Z0-9\-]+$/.test(v) ||
          "Slug must contain only English letters, numbers, and hyphens",
      ],
      loadingSpinner: false,
      alrtState: false,
      alrtText: null,
      snackbar: {
        show: false,
        timeout: 6000,
        type: "info",
        text: "",
      },
    };
  },

  created() {},
  methods: {
    ...mapActions(["addInstituteFunc"]),
    closeDialog() {
      this.$emit("addInstituteDataClose", false);
    },
    handleClickOutside() {},
    saveInstitute() {
      if (!this.$refs.institute_form.validate()) return;
      console.log(this.images);
      this.loadingSpinner = true;
      this.InstituteData.append("name_arabic", this.name_arabic);
      this.InstituteData.append("name_english", this.name_english);
      this.InstituteData.append("link", this.link);
      this.InstituteData.append("link", this.link);
      this.InstituteData.append(`logo`, this.new_image);

      this.addInstituteFunc(this.InstituteData)
        .then(() => {
          this.snackbar.show = true;
          this.snackbar.text = "The institute has been added successfully";
          this.snackbar.type = "success";
          this.$refs.institute_form.reset();

          setTimeout(() => {
            this.snackbar.show = false;
            this.loadingSpinner = false;
            this.closeDialog();
          }, 3000);
        })
        .catch((err) => {
          this.loadingSpinner = false;
          this.snackbar.show = true;
          this.snackbar.text = "An Error occured:" + err;
          this.snackbar.type = "error";
        });
    },
    textFormat(item) {
      if (item.name) {
        if (item.userdirectory) return item.name;
        else return item.name;
      }
    },

    showSnackbar() {
      this.snackbar.show = true;
    },
    updateSnackbar(value) {
      this.snackbar.show = value;
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
