<template>
  <div class="login" dir="rtl">
    <v-container fill-height>
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
          lg="6"
          xl="4"
          xxl="3"
          class="left-contentframe pb-8"
          elevation="2"
        >
          <v-row class="px-8">
            <v-col cols="12" class="pa-0 text-center">
              <img
                style="vertical-align: middle"
                width="40%"
                height="auto"
                src="../../assets/PlatformLogo-01.svg"
                alt
                class="mt-3"
              />
            </v-col>
            <v-col cols="12" class="pt-5 text-center">
              <span class="d-block fa-24 mb-3">منصة مؤكد</span>
              <span class="d-block blue-text grey lighten-4 pa-3">
                الرجاء استخدام معلومات المستخدم لتسجيل الدخول للمنظومة
              </span>
            </v-col>
          </v-row>

          <div class="px-5 mx-5 mt-10">
            <v-form ref="loginForm" lazy-validation>
              <template v-if="!otpPhase">
                <span class="mb-5">ادخل معلومات الدخول في الحقول ادناه</span>
                <v-alert class="pt-3" v-if="feedback" dense type="warning">
                  {{ feedback }}
                </v-alert>

                <v-text-field
                  auto
                  label="Username"
                  required
                  placeholder="Username"
                  class="mt-2"
                  v-model="cred.email"
                  dense
                  outlined
                  variant="outlined"
                  :rules="requiredRule"
                ></v-text-field>

                <v-text-field
                  label="Password"
                  required
                  placeholder="Password"
                  type="password"
                  class="mt-2"
                  dense
                  outlined
                  variant="outlined"
                  v-model="cred.password"
                  :rules="requiredRule"
                  @keyup.enter="login"
                ></v-text-field>

                <v-btn
                  class="login-system-btn text-capitalize mt-5"
                  dir="rtl"
                  outlined
                  elevation="0"
                  text
                  @click="login"
                >
                  <v-icon left class="mx-2">mdi-login</v-icon> تسجيل دخول
                </v-btn>
              </template>

              <template v-else>
                <span class="mb-5"
                  >ادخل رمز التحقق الذي تم إرساله إلى بريدك الإلكتروني</span
                >
                <v-alert class="pt-3" v-if="feedback" dense type="warning">
                  {{ feedback }}
                </v-alert>

                <v-text-field
                  label="OTP"
                  required
                  placeholder="Enter OTP"
                  class="mt-2"
                  v-model="otp"
                  dense
                  outlined
                  variant="outlined"
                  :rules="requiredRule"
                  @keyup.enter="login"
                ></v-text-field>

                <v-btn
                  class="login-system-btn text-capitalize mt-5"
                  dir="rtl"
                  outlined
                  elevation="0"
                  text
                  @click="login"
                  @keyup.enter="login"
                >
                  <v-icon left class="mx-2">mdi-check</v-icon> تأكيد
                </v-btn>
              </template>

              <span class="d-block eng-text blue-text comment-text mt-6">
                &copy; The Government of Iraq
              </span>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
//login script
import { mapActions } from "vuex";
export default {
  name: "LoginPage",
  data: function () {
    return {
      cred: {
        email: "",
        password: "",
      },
      otp: null,
      feedback: "",
      loading: false,
      otpPhase: false, // Tracks whether OTP verification is active
      requiredRule: [(v) => !!v || "Required Fields"],
    };
  },
  methods: {
    ...mapActions(["loginFun", "attempt"]),
    login() {
      if (!this.$refs.loginForm.validate()) return;
      this.loading = true;
      const formData = new FormData();
      formData.append("email", this.cred.email);
      formData.append("password", this.cred.password);
      if (this.otp) {
        formData.append("otp", this.otp);
      }
      this.loginFun(formData)
        .then((response) => {
          this.loading = false;
          if (response.message === "OTP sent. Please verify OTP to continue.") {
            this.otpPhase = true;
            this.feedback = "";
          } else if (response === "success") {
            this.$router.push("/control-panel/dashboard");
          } else {
            console.log(response);
            this.feedback = "Incorrect username or password";
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          switch (error.response?.status) {
            case 401:
              this.feedback = "Username and password are incorrect";
              break;
            case 400:
              this.feedback = "Your account has been banned";
              break;
            default:
              this.feedback = "Incorrect username or password";
              break;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
$accentColor: #2196f3;
$textColor: #fff;
$fcolor: #f1f1f1;
$bcolor: #f1f1f1;
$bgcolor: white;
$bgimage_path: "../assets/imgs/back.jpg";
$fontname: "JF Flat";
.login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient($fcolor, $bcolor);

  display: flex;
  justify-content: center;
  align-items: center;
}
.left-contentframe {
  background: $bgcolor;
  //box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.right-contentframe {
  background: $bgcolor;
  border: none;
  border-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-top: 4vh;
}

.login-system-btn {
  background-color: #03a9f4;
  color: #f1f1f1;
  border: none;
  font-weight: light;
}

.blue-text-2 {
  color: #5dc7e6;
  padding: 5px;
  border-radius: 0px;
  font-weight: bolder;
}
</style>
