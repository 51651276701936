<template>
  <div v-if="addUserDialog" class="text-center" dir="rtl">
    <v-dialog
      :value="addUserDialog"
      width="60vh"
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card v-click-outside="handleClickOutside">
        <v-toolbar light class="mb-5" dir="rtl">
          <v-btn icon light @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="me-5"> اضافة مستخدم </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="closeDialog"> Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form ref="user_form" dir="rtl">
              <v-row>
                <v-col cols="12" class="blue lighten-5 rounded mb-1">
                  <span class="d-block"
                    ><v-icon>mdi-alert-circle-outline</v-icon> الرجاء مليء جميع
                    الحقول المطلوبة لغرض تسجيل البيانات ضمن المنصة وباللغتين
                    العربية والانكليزية
                  </span>
                </v-col>

                <v-col cols="12" md="12" lg="12" xl="12" xxl="12" class="">
                  <v-text-field
                    v-model="name"
                    type="text"
                    placeholder="الاسم الكامل"
                    outlined
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="12" xl="12" xxl="12" class="">
                  <v-text-field
                    v-model="email"
                    type="email"
                    placeholder="البريد الالكتروني"
                    outlined
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="12" xl="12" xxl="12" class="">
                  <v-select
                    v-model="role"
                    :items="userRoles"
                    outlined
                    hide-details="auto"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12" lg="12" xl="12" xxl="12" class="">
                  <v-col cols="12" class="orange lighten-5 rounded mb-4">
                    <span class="d-block"
                      ><v-icon>mdi-alert-circle-outline</v-icon> يجب ان تحتوي
                      كلمة السر على 6 مكونات ويجب ان تحتوي على (ارقام / حروف /
                      رموز / حروف كبيرة)
                    </span>
                  </v-col>

                  <v-text-field
                    v-model="password"
                    type="password"
                    placeholder="كلمة السر"
                    outlined
                    :rules="[...requiredRule, validatePasswordRule]"
                  ></v-text-field>
                  <v-text-field
                    v-model="mobile"
                    label="Mobile Number"
                    :rules="[requiredRule, mobileNumberRule]"
                    outlined
                    placeholder="77xxxxxxxx, 78xxxxxxxx, 79xxxxxxxx, 75xxxxxxxx"
                    maxlength="10"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mx-auto">
                  <SnackbarComponent
                    :show="snackbar.show"
                    :timeout="snackbar.timeout"
                    :type="snackbar.type"
                    :text="snackbar.text"
                    @update:show="updateSnackbar"
                    @close-snackbar="closeSnackbar"
                  />
                  <v-btn
                    :loading="loadingSpinner"
                    :disabled="loadingSpinner"
                    class="blue lighten-0 white--text"
                    elevation="0"
                    @click="registerUser()"
                    ><v-icon>mdi-database</v-icon> تسجيل المستخدم</v-btn
                  >
                  <v-btn color="primary" text @click="closeDialog">
                    اغلاق
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

export default {
  label: "AddUser",
  props: ["addUserDialog"],

  components: {
    SnackbarComponent,
  },
  computed: {
    ...mapGetters(["allCategories", "allFlattenedCategories", "allBrands"]),
  },
  data() {
    return {
      name: null,
      email: null,
      password: null,
      mobile: null,
      role: "user",
      userData: new FormData(),
      requiredRule: [(v) => !!v || "This field is required"],
      requiredRule2: [(v) => v.length > 0 || "This field is required"],
      loadingSpinner: false,
      userRoles: [
        { text: "administrator", value: "administrator" },
        { text: "editor", value: "editor" },
      ],
      mobileNumberRule: (v) => {
        const regex = /^(77|78|79|75)\d{8}$/;
        return (
          regex.test(v) ||
          "The phone number must start with 77, 78, 75, or 79 and consist of exactly 10 digits."
        );
      },
      snackbar: {
        show: false,
        timeout: 6000,
        type: "info",
        text: "",
      },
    };
  },

  created() {},
  methods: {
    ...mapActions(["addUserFunc"]),

    closeDialog() {
      this.$emit("addUserDialogClose", false);
    },
    handleClickOutside() {},
    validatePasswordRule(value) {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

      if (!regex.test(value)) {
        return "كلمة السر يجب أن تحتوي على (أحرف صغيرة، أحرف كبيرة، أرقام، رموز)";
      }
      // You can add additional checks if needed
      // For example: checking the length, not including username, etc.
      return true;
    },
    registerUser() {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
      if (!passwordRegex.test(this.password)) {
        this.snackbar.show = true;
        this.snackbar.text =
          "كلمة السر يجب أن تحتوي على (أحرف صغيرة، أحرف كبيرة، أرقام، رموز)";
        this.snackbar.type = "error";
        return;
      }
      if (!this.$refs.user_form.validate()) return;
      this.loadingSpinner = true;
      this.userData.append("name", this.name);
      this.userData.append("email", this.email);
      this.userData.append("password", this.password);
      this.userData.append("phone", this.mobile);
      this.userData.append("role", this.role);

      this.addUserFunc(this.userData)
        .then(() => {
          this.snackbar.show = true;
          this.snackbar.text = "تم تسجيل حساب المستخدم بنجاح";
          this.snackbar.type = "success";
          setTimeout(() => {
            this.snackbar.show = false;
            this.loadingSpinner = false;
            this.$refs.user_form.reset();
            this.closeDialog();
          }, 3000);
        })
        .catch((err) => {
          this.loadingSpinner = false;
          this.snackbar.show = true;
          this.snackbar.text = "An Error occured:" + err;
          this.snackbar.type = "error";
        });
    },

    showSnackbar() {
      this.snackbar.show = true;
    },
    updateSnackbar(value) {
      this.snackbar.show = value;
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
