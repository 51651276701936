import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import categories from "./modules/categories";
import articles from "./modules/articles";
import users from "./modules/user";
import institutes from "./modules/institutes";
import log from "./modules/log";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},

  modules: { auth, categories, articles, users, log, institutes },
});
