<template>
  <div class="home-page small-text">
    <v-card-text class="pa-0 mb-4"
      >مدير المنشورات
      <v-btn
        color="blue"
        elevation="0"
        class="small-text white--text me-5"
        @click="addArticleDialog = true"
      >
        <v-icon right dark class="ms-2"> mdi-plus-circle-outline </v-icon>
        منشور جديد
      </v-btn>

      <v-btn
        color="black"
        elevation="0"
        class="small-text white--text me-5"
        @click="showSearchModal = true"
      >
        <v-icon right dark class="ms-2"> mdi-magnify </v-icon>
        بحث
      </v-btn>
    </v-card-text>

    <v-card elevation="0">
      <v-card-text>
        <v-data-table
          align-center
          :headers="headers"
          :items="allArticles"
          hide-default-footer
          fixed-header
          :options="{ itemsPerPage: -1 }"
        >
          <!-- Custom slot for the content column -->
          <template #[`item.content`]="{ item }">
            <span class="text-truncate-two-lines">{{ item.title }}</span>
          </template>
          <!-- Custom slot for the date column -->
          <template #[`item.created_at`]="{ item }">
            <span class="no-wrap">{{ formatDate(item.created_at) }}</span>
          </template>
          <template #[`item.options`]="{ item }">
            <v-btn
              elevation="0"
              class="blue small-text white--text lighten-2"
              @click="showArticleDetails(item)"
              ><v-icon small class="ms-1">mdi-file-edit </v-icon> التفاصيل
            </v-btn>
          </template>
        </v-data-table>
        <!-- pagination -->
        <v-pagination
          dir="ltr"
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
          active-color="#2196F3"
          color="#2196F3"
          :disabled="disablePagination"
        ></v-pagination>
        <!-- pagination // -->
      </v-card-text>
    </v-card>

    <AddArticleDialog
      :addArticleDialog="addArticleDialog"
      @addArticleDialogClose="addArticleDialog = $event"
    />

    <EditArticleDialog
      :EditArticleDialog="EditArticleDialog"
      :currentArticle="articleData"
      @editArticleDialogClosed="EditArticleDialog = $event"
    />
    <search-modal
      :showModal="showSearchModal"
      :searchQuery.sync="searchQuery"
      @searchModelClosed="showSearchModal = $event"
    />
    <loading :isLoading="loading"></loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "@/components/LoadingScreen.vue";
import AddArticleDialog from "@/components/control/article/AddArticleDialog.vue";
import EditArticleDialog from "@/components/control/article/ArticleDetailsDialog.vue";
import SearchModal from "@/components/SearchModal.vue";

export default {
  name: "ArticlePage",
  components: {
    AddArticleDialog,
    EditArticleDialog,
    Loading,
    SearchModal,
  },
  data() {
    return {
      loading: false,
      addArticleDialog: false,
      showSearchModal: false,
      searchQuery: null,
      headers: [
        { text: "العنوان", value: "title_arabic" },
        { text: "التفاصيل", value: "content_arabic" },
        { text: "التصنيف", value: "category_name_arabic" },
        { text: "التاريخ والوقت", value: "created_at" },
        { text: "خيارات", value: "options" },
      ],
      EditArticleDialog: false,
      articleData: null,
      disablePagination: false,
      search: null,
      info: {
        page: 1,
        keyword: null,
      },
      interval: null,
    };
  },
  computed: {
    ...mapGetters(["allArticles", "getRole", "getUser"]),

    currentGet: {
      get() {
        return this.$store.getters.getArticleCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setArticleCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getArticleLastPage;
      },
    },
  },

  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.info.page = newVal;
        this.info.keyword = this.search;
        this.disablePagination = true;
        this.loading = true;
        this.fetchArticles(this.info)
          .then(() => {
            this.loading = false;
            this.disablePagination = false;
          })
          .catch(() => {
            this.loading = false;
            this.disablePagination = false;
          });
      }
    },
  },

  created() {
    this.disablePagination = true;
    this.loading = true;
    this.fetchArticles(this.info)
      .then(() => {
        this.loading = false;
        this.disablePagination = false;
      })
      .catch(() => {
        this.loading = false;
        this.disablePagination = false;
      });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions(["fetchArticles"]),
    searchInTable() {
      this.info.page = 1;
      this.info.keyword = this.search;
      this.loading = true;
      this.fetchArticles(this.info)
        .then(() => {
          this.loading = false;
          this.disablePagination = false;
        })
        .catch(() => {
          this.loading = false;
          this.disablePagination = false;
        });
    },

    showArticleDetails(item) {
      this.articleData = item;
      this.EditArticleDialog = true;
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
};
</script>
<style scoped>
.text-truncate-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no-wrap {
  white-space: nowrap;
}
</style>
