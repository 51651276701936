<template>
  <div class="home-page small-text">
    <v-card-text class="pa-0 mb-4"
      >مدير الحسابات
      <v-btn
        color="blue"
        elevation="0"
        class="small-text white--text me-5"
        @click="addUserDialog = true"
        v-if="
          authenticated.role == 'administrator' ||
          authenticated.role == 'developer'
        "
      >
        <v-icon right dark class="ms-2"> mdi-plus-circle-outline </v-icon>
        حساب مستخدم جديد
      </v-btn></v-card-text
    >
    <v-card elevation="0">
      <v-card-text>
        <v-data-table
          align-center
          :headers="headers"
          :items="allUsersData"
          hide-default-footer
          fixed-header
          :options="{ itemsPerPage: -1 }"
        >
          <template #[`item.status`]="{ item }">
            <div v-if="authenticated.role == 'administrator'">
              <v-select
                v-model="item.status"
                :items="activationOptions"
                outlined
                dense
                hide-details="auto"
                item-text="text"
                item-value="value"
                @change="activateAccount(item)"
              ></v-select>
            </div>
            <div v-else>
              <span>{{ item.status }}</span>
            </div>
          </template>
          <!-- Custom slot for the date column -->
          <template #[`item.created_at`]="{ item }">
            <span class="no-wrap">{{ formatDate(item.created_at) }}</span>
          </template>
          <template #[`item.options`]="{ item }">
            <v-btn
              v-if="authenticated.role == 'administrator'"
              elevation="0"
              class="blue small-text white--text lighten-2"
              @click="showUserDetails(item)"
              ><v-icon small class="ms-1">mdi-file-edit </v-icon> التفاصيل
            </v-btn>
          </template>
        </v-data-table>
        <!-- pagination -->
        <!--  <v-pagination
          dir="ltr"
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
          active-color="#2196F3"
          color="#2196F3"
          :disabled="disablePagination"
        ></v-pagination> -->
        <!-- pagination // -->
      </v-card-text>
    </v-card>

    <AddUserDialog
      :addUserDialog="addUserDialog"
      @addUserDialogClose="addUserDialog = $event"
    />

    <EditUserDialog
      :EditUserDialog="EditUserDialog"
      :currentUser="userData"
      @editUserDialogClose="EditUserDialog = $event"
    />
    <SnackbarComponent
      :show="snackbar.show"
      :timeout="snackbar.timeout"
      :type="snackbar.type"
      :text="snackbar.text"
      @update:show="updateSnackbar"
      @close-snackbar="closeSnackbar"
    />

    <loading :isLoading="loading"></loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "@/components/LoadingScreen.vue";
import AddUserDialog from "@/components/control/user/AddUserDialog.vue";
import EditUserDialog from "@/components/control/user/UserDetailsDialog.vue";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

export default {
  name: "UserPage",
  components: {
    AddUserDialog,
    EditUserDialog,
    Loading,
    SnackbarComponent,
  },
  data() {
    return {
      loading: false,
      addUserDialog: false,
      activationOptions: ["active", "inactive", "suspended"],
      headers: [
        { text: "الاسم الكامل", value: "name" },
        { text: "البريد الالكتروني", value: "email" },
        { text: "نوع المستخدم", value: "role" },
        { text: "حالة الحساب", value: "status" },
        { text: "تاريخ التفعيل", value: "created_at" },
        { text: "التفاصيل", value: "options" },
      ],
      EditUserDialog: false,
      userData: null,
      disablePagination: false,
      search: null,
      allUsers: [],
      info: {
        page: 1,
        keyword: null,
      },

      snackbar: {
        show: false,
        timeout: 6000,
        type: "info",
        text: "",
      },
      interval: null,

      showSnackbar() {
        this.snackbar.show = true;
      },
      updateSnackbar(value) {
        this.snackbar.show = value;
      },
      closeSnackbar() {
        this.snackbar.show = false;
      },
    };
  },
  computed: {
    ...mapGetters(["allUsersData", "authenticated"]),

    currentGet: {
      get() {
        return this.$store.getters.getUserCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setUserCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getUserLastPage;
      },
    },
  },

  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.info.page = newVal;
        this.info.keyword = this.search;
        this.disablePagination = true;
        this.loading = true;
        this.fetchUsers(this.info)
          .then(() => {
            this.loading = false;
            this.disablePagination = false;
          })
          .catch(() => {
            this.loading = false;
            this.disablePagination = false;
          });
      }
    },
  },

  created() {
    this.disablePagination = true;
    this.loading = true;
    this.fetchUsers(this.info)
      .then(() => {
        this.loading = false;
        this.disablePagination = false;
      })
      .catch(() => {
        this.loading = false;
        this.disablePagination = false;
      });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions(["fetchUsers", "changeAccountActivation"]),
    searchInTable() {
      this.info.page = 1;
      this.info.keyword = this.search;
      this.loading = true;
      this.fetchUsers(this.info)
        .then(() => {
          this.loading = false;
          this.disablePagination = false;
        })
        .catch(() => {
          this.loading = false;
          this.disablePagination = false;
        });
    },

    activateAccount(user) {
      this.changeAccountActivation(user)
        .then(() => {
          this.snackbar.show = true;
          this.snackbar.text = "تم تغيير حالة الحساب الى " + user.status;
          this.snackbar.type = "success";
          setTimeout(() => {
            this.snackbar.show = false;
          }, 3000);
        })
        .catch((err) => {
          this.snackbar.show = true;
          this.snackbar.text = "An Error occurred:" + err;
          this.snackbar.type = "error";
        });
    },

    showUserDetails(item) {
      this.userData = item;
      this.EditUserDialog = true;
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
};
</script>
<style scoped>
.text-truncate-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no-wrap {
  white-space: nowrap;
}
</style>
