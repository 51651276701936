<template>
  <v-dialog
    v-if="DeleteInstituteDialog && currentInstitute"
    :value="DeleteInstituteDialog"
    width="50%"
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card v-click-outside="handleClickOutside">
      <v-toolbar light class="mb-5" dir="rtl">
        <v-btn icon light @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> حذف المؤسسة</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text @click="closeDialog"> Close</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-form ref="institute_form">
            <v-row>
              <v-col cols="12" class="mx-auto text-center">
                <SnackbarComponent
                  :show="snackbar.show"
                  :timeout="snackbar.timeout"
                  :type="snackbar.type"
                  :text="snackbar.text"
                  @update:show="updateSnackbar"
                  @close-snackbar="closeSnackbar"
                />
                <v-btn
                  :loading="loadingSpinner"
                  :disabled="loadingSpinner"
                  color="red"
                  class="white--text"
                  elevation="0"
                  @click="deleteInstitute()"
                  >Delete data</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

export default {
  label: "DeleteInstituteDetails",

  props: ["DeleteInstituteDialog", "currentInstitute"],
  components: {
    SnackbarComponent,
  },
  computed: {
    ...mapGetters(["allFlattenedCategories", "getFlattenedInstitute"]),
  },
  data() {
    return {
      instituteData: Object.assign({}, this.currentInstitute),
      loadingSpinner: false,
      alrtState: false,
      alrtText: null,
      snackbar: {
        show: false,
        timeout: 6000,
        type: "info",
        text: "",
      },
    };
  },

  watch: {
    currentInstitute(newVal) {
      this.instituteData = Object.assign({}, newVal);
    },
  },

  methods: {
    ...mapActions(["deleteInstituteFunc"]),

    closeDialog() {
      this.$emit("DeleteInstituteDialogClose", false);
    },
    handleClickOutside() {},

    deleteInstitute() {
      this.loadingSpinner = true;
      this.deleteInstituteFunc(this.instituteData) // Corrected to call the Vuex action
        .then(() => {
          this.snackbar.show = true;
          this.snackbar.text = "The institute has been deleted successfully";
          this.snackbar.type = "success";
          setTimeout(() => {
            this.snackbar.show = false;
            this.loadingSpinner = false;
            this.closeDialog();
          }, 3000);
        })
        .catch((err) => {
          this.loadingSpinner = false;
          this.snackbar.show = true;
          this.snackbar.text = "An error occurred: " + err;
          this.snackbar.type = "error";
        });
    },

    textFormat(item) {
      if (item.name) {
        if (item.userdirectory) return item.name;
        else return item.name;
      }
    },

    showSnackbar() {
      this.snackbar.show = true;
    },
    updateSnackbar(value) {
      this.snackbar.show = value;
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
