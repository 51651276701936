<template>
  <div v-if="addCategoryDialog" class="text-center">
    <v-dialog
      width="600px"
      :value="addCategoryDialog"
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card v-click-outside="handleClickOutside">
        <v-toolbar light class="mb-5">
          <v-btn icon light @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> اضافة تصنيفات </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="closeDialog"> Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form ref="company_form">
              <v-row>
                <v-col cols="12" class="blue lighten-5 rounded mb-2">
                  <span class="d-block"
                    ><v-icon>mdi-alert-circle-outline</v-icon> الرجاء مليء جميع
                    الحقول لغرض اضافة التصنيفات</span
                  >
                </v-col>

                <v-col cols="12" md="12" lg="12" xl="12" xxl="12">
                  <v-text-field
                    v-model="name"
                    item-value="id"
                    outlined
                    required
                    clearable
                    :rules="requiredRule"
                    placeholder="اسم التصنيف"
                    label="اسم التصنيف"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="12" xl="12" xxl="12">
                  <v-text-field
                    v-model="name_en"
                    item-value="id"
                    outlined
                    required
                    clearable
                    :rules="requiredRule"
                    placeholder="اسم التصنيف باللغة الالنكليزية"
                    label="اسم التصنيف باللغة الالنكليزية"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="12" xl="12" xxl="12">
                  <v-text-field
                    v-model="slug"
                    outlined
                    required
                    clearable
                    :rules="slugRules"
                    placeholder="Slug (must be in English and no spaces)"
                    label="Slug"
                    @keydown.space.prevent
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-col cols="12" class="mx-auto">
                <SnackbarComponent
                  :show="snackbar.show"
                  :timeout="snackbar.timeout"
                  :type="snackbar.type"
                  :text="snackbar.text"
                  @update:show="updateSnackbar"
                  @close-snackbar="closeSnackbar"
                />
                <v-btn
                  :loading="loadingSpinner"
                  :disabled="loadingSpinner"
                  color="primary"
                  @click="registerProduct()"
                  ><v-icon>mdi-database</v-icon> Register Category</v-btn
                >
                <v-btn color="primary" text @click="closeDialog"> Close </v-btn>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

export default {
  label: "AddCategory",
  props: ["addCategoryDialog"],

  components: {
    SnackbarComponent,
  },
  computed: {
    ...mapGetters(["allFlattenedCategories"]),
  },
  data() {
    return {
      category: null,
      slug: "", // New slug field
      categoryData: new FormData(),
      requiredRule: [(v) => !!v || "This field is required"],
      requiredRule2: [(v) => v.length > 0 || "This field is required"],
      slugRules: [
        (v) => !!v || "Slug is required",
        (v) =>
          /^[a-zA-Z0-9\-]+$/.test(v) ||
          "Slug must contain only English letters, numbers, and hyphens",
      ],
      loadingSpinner: false,
      alrtState: false,
      alrtText: null,
      snackbar: {
        show: false,
        timeout: 6000,
        type: "info",
        text: "",
      },
    };
  },

  created() {},
  methods: {
    ...mapActions(["addCategoryFunc"]),

    closeDialog() {
      this.$emit("addCategoryDataClose", false);
    },
    handleClickOutside() {},

    registerProduct() {
      if (!this.$refs.company_form.validate()) return;
      console.log(this.images);
      this.loadingSpinner = true;
      this.categoryData.append("name_arabic", this.name);
      this.categoryData.append("name_english", this.name_en);
      this.categoryData.append("slug", this.slug);

      this.addCategoryFunc(this.categoryData)
        .then(() => {
          this.snackbar.show = true;
          this.snackbar.text = "The category has been added successfully";
          this.snackbar.type = "success";

          setTimeout(() => {
            this.snackbar.show = false;
            this.loadingSpinner = false;
            this.closeDialog();
          }, 3000);
        })
        .catch((err) => {
          this.loadingSpinner = false;
          this.snackbar.show = true;
          this.snackbar.text = "An Error occured:" + err;
          this.snackbar.type = "error";
        });
    },
    textFormat(item) {
      if (item.name) {
        if (item.userdirectory) return item.name;
        else return item.name;
      }
    },

    showSnackbar() {
      this.snackbar.show = true;
    },
    updateSnackbar(value) {
      this.snackbar.show = value;
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
