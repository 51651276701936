import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./scss/main.scss";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);
Vue.use(VueCookies, { expire: "7d" });
Vue.prototype.$globalLink = "Home";
Vue.config.productionTip = false;
import "@/store/modules/subscriber";
window.Event = new Vue();

store.dispatch("attempt", localStorage.getItem("user_token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
