import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/public/Login";
import ControlPanel from "../views/control/Layout.vue";
import DashboardPage from "../views/control/DashboardPage.vue";
import CategoriesPage from "../views/control/CategoriesPage.vue";
import PostManager from "../views/control/PostManager.vue";
import AccountManager from "../views/control/AccountManager.vue";
import UserInfo from "../views/control/UserInfo.vue";
import LogManager from "../views/control/LogManager.vue";
import ReportManager from "../views/control/ReportPage.vue";
import UserGuide from "../views/control/UserGuide.vue";
import Institutes from "../views/control/Institutes.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    name: "LoginPage",
    path: "/",
    component: LoginPage,
  },
  {
    path: "/control-panel",
    name: "control-panel",
    component: ControlPanel,
    redirect: "/control-panel/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: DashboardPage,
      },
      {
        path: "categories",
        name: "Categories",
        component: CategoriesPage,
      },

      {
        path: "post-manager",
        name: "Post Manager",
        component: PostManager,
      },
      {
        path: "report-manager",
        name: "report-manager",
        component: ReportManager,
      },
      {
        path: "user-guide",
        name: "user-guide",
        component: UserGuide,
      },
      {
        path: "account-manager",
        name: "account-manager",
        component: AccountManager,
      },
      {
        path: "log-manager",
        name: "log-manager",
        component: LogManager,
      },
      {
        path: "user-page",
        name: "user-page",
        component: UserInfo,
      },
      {
        path: "institutes",
        name: "institutes",
        component: Institutes,
      },
    ],
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "LoginPage",
        });
      }
      next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // Assuming you have a getter named 'isLoggedIn' in your store
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.getters["isLoggedIn"]
  ) {
    return next({
      name: "LoginPage",
    });
  }
  return next();
});

export default router;
