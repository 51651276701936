<template>
  <div class="footer-height mt-2" color="#FFFFFF">
    <v-row dir="rtl">
      <v-col cols="12" lg="12" md="12" sm="12">
        <p class="grayFont fa-12">
          <v-spacer></v-spacer>
          &copy; حقوق البرمجة محفوظة لدى هيئة الاعلام والاتصالات / قسم الاتمتة
          والدعم التقني
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fixedVar: true,
    };
  },
  created() {
    if (this.$vuetify.breakpoint.mdAndUp === true) {
      this.fixedVar = true;
    } else {
      this.fixedVar = false;
    }
  },
};
</script>

<style>
@media only screen and (max-width: 600px) {
}
</style>
