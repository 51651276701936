<template>
  <v-dialog
    v-if="EditCategoryDialog && currentCategory"
    :value="EditCategoryDialog"
    width="800px"
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card v-click-outside="handleClickOutside">
      <v-toolbar light class="mb-5">
        <v-btn icon light @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> تفاصيل التصنيفات </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text @click="closeDialog"> Close</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-form ref="category_form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="categoryData.name_arabic"
                  outlined
                  required
                  clearable
                  :rules="requiredRule"
                  :item-text="textFormat"
                  placeholder="التصنيفات"
                  label="التصنيفات"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="categoryData.name_english"
                  outlined
                  required
                  clearable
                  :rules="requiredRule"
                  :item-text="textFormat"
                  placeholder="Category in English"
                  label="Category in English"
                >
                </v-text-field>
              </v-col>
              <div v-if="categoryData.children != []">
                <v-col cols="12">
                  <v-row
                    v-for="(child, index) in categoryData.children"
                    :key="index"
                  >
                    <v-col
                      cols="12"
                      md="10"
                      lg="10"
                      xl="10"
                      xxl="10"
                      class="mt-3"
                    >
                      <v-text-field
                        v-model="child.name_arabic"
                        outlined
                        clearable
                        :rules="requiredRule"
                        :item-text="textFormat"
                        placeholder="التصنيف"
                        label="التصنيف"
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="child.name_english"
                        outlined
                        clearable
                        :rules="requiredRule"
                        :item-text="textFormat"
                        placeholder="Category Name"
                        label="Category Name"
                      >
                      </v-text-field>

                      <v-text-field
                        v-model="child.slug"
                        outlined
                        required
                        clearable
                        :rules="slugRules"
                        placeholder="Slug (must be in English and no spaces)"
                        label="Slug"
                        @keydown.space.prevent
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="2" xl="2" xxl="2" class="mt-5">
                      <v-btn
                        color="error"
                        class="lighten-3 small-text"
                        elevation="0"
                        @click="removeChild(index)"
                      >
                        <v-icon>mdi-delete</v-icon> Delete
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
              <v-col cols="12">
                <v-btn
                  :loading="loadingSpinner"
                  :disabled="loadingSpinner"
                  color="primary"
                  @click="addMoreCategories()"
                  ><v-icon>mdi-plus</v-icon> Add More Children</v-btn
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="mx-auto">
                <SnackbarComponent
                  :show="snackbar.show"
                  :timeout="snackbar.timeout"
                  :type="snackbar.type"
                  :text="snackbar.text"
                  @update:show="updateSnackbar"
                  @close-snackbar="closeSnackbar"
                />
                <v-btn
                  :loading="loadingSpinner"
                  :disabled="loadingSpinner"
                  color="primary"
                  @click="saveCategory()"
                  >Edit data</v-btn
                >
                <v-btn color="primary" text @click="closeDialog"> Close </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

export default {
  label: "EditProductDetails",

  props: ["EditCategoryDialog", "currentCategory"],
  components: {
    SnackbarComponent,
  },
  computed: {
    ...mapGetters(["allFlattenedCategories", "getFlattenedCategory"]),
  },
  data() {
    return {
      categoryData: Object.assign({}, this.currentCategory),
      requiredRule: [(v) => !!v || "This field is required"],
      requiredRule2: [(v) => v.length > 0 || "This field is required"],
      slugRules: [
        (v) => !!v || "Slug is required",
        (v) =>
          /^[a-zA-Z0-9\-]+$/.test(v) ||
          "Slug must contain only English letters, numbers, and hyphens",
      ],
      loadingSpinner: false,
      alrtState: false,
      alrtText: null,
      snackbar: {
        show: false,
        timeout: 6000,
        type: "info",
        text: "",
      },
    };
  },

  watch: {
    currentCategory(newVal) {
      this.categoryData = Object.assign({}, newVal);
    },
  },

  created() {
    this.fetchFlattenedCategories();
  },
  methods: {
    ...mapActions([
      "fetchFlattenedCategories",
      "editCategoryFunc",
      "addCategoryFunc",
    ]),

    removeChild(index) {
      this.categoryData.children.splice(index, 1);
    },
    addMoreCategories() {
      this.categoryData.children.push({
        id: null,
      });
    },

    closeDialog() {
      this.$emit("editCategoryDialogClose", false);
    },
    handleClickOutside() {},

    saveCategory() {
      if (!this.$refs.category_form.validate()) return;
      this.loadingSpinner = true;

      // Create an array of promises for each child edit request
      const editPromises = this.categoryData.children.map((child) => {
        const existingCategory = this.getFlattenedCategory(child.id);

        if (existingCategory) {
          // Category exists, perform edit
          let childData = new FormData();
          childData.append("name_arabic", child.name);
          childData.append("name_english", child.name_en);
          childData.append("parent_id", this.categoryData.id);
          childData.append("slug", child.slug);
          childData.append("_method", "PATCH");
          return this.editCategoryFunc(child);
        } else {
          let childData = new FormData();
          childData.append("name_arabic", child.name);
          childData.append("name_english", child.name_en);
          childData.append("slug", child.slug);
          childData.append("parent_id", this.categoryData.id);
          // Category doesn't exist, perform addition
          return this.addCategoryFunc(childData); // Assuming you have a function to add a category
        }
      });

      //when finishes all child editing process
      Promise.all(editPromises)
        .then(() => {
          this.editCategoryFunc(this.categoryData)
            .then(() => {
              this.snackbar.show = true;
              this.snackbar.text = "The category has been edited successfully";
              this.snackbar.type = "success";
              this.loadingSpinner = false;

              setTimeout(() => {
                this.snackbar.show = false;
                this.loadingSpinner = false;
                this.closeDialog();
                location.reload();
              }, 3000);
            })
            .catch((err) => {
              console.error(err);
              this.loadingSpinner = false;
              this.snackbar.show = true;
              this.snackbar.text = "An Error occured:" + err;
              this.snackbar.type = "error";
            });
        })
        .catch((err) => {
          console.error(err);
          this.loadingSpinner = false;
          this.snackbar.show = true;
          this.snackbar.text = "An Error in updating childs:" + err;
          this.snackbar.type = "error";
        });
    },
    textFormat(item) {
      if (item.name) {
        if (item.userdirectory) return item.name;
        else return item.name;
      }
    },

    showSnackbar() {
      this.snackbar.show = true;
    },
    updateSnackbar(value) {
      this.snackbar.show = value;
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
