import axios from "@/axios";

const state = {
  reportData: {},
  articles: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },
};

const getters = {
  allArticles: (state) => state.articles.data,
  getReportData: (state) => state.reportData,
  getArticle: (state) => (id) => {
    return state.articles.data.find((articles) => articles.id === id);
  },
  getArticleProfile: (state) => state.article,
  getArticleCurrentPage: (state) =>
    state.articles.pagination ? state.articles.pagination.current_page : 1,
  getArticleLastPage: (state) =>
    state.articles.pagination ? state.articles.pagination.last_page : 1,
};
const actions = {
  async fetchArticles({ commit }, info) {
    var page = info.page;
    var keyword = info.search;
    // define how many record are fetched per page
    var perPage = info.perPage ? info.perPage : 15;
    if (this.getters["getRole"] == "administrator") {
      if (keyword != "" && keyword != null) {
        const response = await axios.get(
          "/" +
            this.getters["getRole"] +
            "/articles?per_page=" +
            perPage +
            "&page=" +
            page +
            "&search=" +
            keyword
        );
        commit("SET_ARTICLE_DATA", response.data);
      } else {
        const response = await axios.get(
          "/" +
            this.getters["getRole"] +
            "/articles?per_page=" +
            perPage +
            "&page=" +
            page
        );
        commit("SET_ARTICLE_DATA", response.data);
      }
    } else {
      if (keyword != "" && keyword != null) {
        const response = await axios.get(
          "/" +
            this.getters["getRole"] +
            "/articles?per_page=" +
            perPage +
            "&page=" +
            page +
            "&search=" +
            keyword
        );
        commit("SET_ARTICLE_DATA", response.data);
      } else {
        const response = await axios.get(
          "/" +
            this.getters["getRole"] +
            "/articles?per_page=" +
            perPage +
            "&page=" +
            page
        );
        commit("SET_ARTICLE_DATA", response.data);
      }
    }
  },

  // fetch limited article
  async fetchLimitedArticles({ commit }, limit) {
    const response = await axios.get(
      "/" + this.getters["getRole"] + "/articles?limit=" + limit
    );
    commit("SET_ARTICLE_DATA", response.data);
  },

  // fetch limited article
  async fetchArticleBySlug({ commit }, slug) {
    try {
      const response = await axios.get(
        "/" + this.getters["getRole"] + "/article/" + slug
      );
      if (response.status === 200) {
        commit("SET_ARTICLE_PROFILE", response.data[0]);
      } else {
        throw new Error("Failed to fetch article");
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  //EDIT ARTICLES
  async editArticle({ commit }, info) {
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/articles/" + info.get("id"),
      info
    );
    if (response.status == 200) commit("EDIT_ARTICLE_DATA", response.data.data);
  },

  async fetchReportData({ commit }, info) {
    try {
      const response = await axios.post(
        "/" + this.getters["getRole"] + "/statistics/all",
        info
      );
      if (response.status == 200) commit("SET_REPORT_DATA", response.data);
    } catch (error) {
      console.error(error);
      return null; // Return a default value or handle the error appropriately
    }
  },
  async addArticleFunc({ commit }, Article) {
    console.log("My REQUEST is ", Article);
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/articles",
      Article
    );
    if (response.status == 201) {
      commit("ADD_ARTICLE", response.data.data);
      return "success";
    } else return "failed";
  },

  async deleteArticle({ commit }, info) {
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/articles/" + info.id,
      {
        _method: "delete",
      }
    );
    if (response.status == 200) commit("DELETE_ARTICLE", info);
    return response.data;
  },

  setArticleCurrentPage({ commit }, currentPage) {
    commit("SET_ARTICLE_CURRENT_PAGE", currentPage);
  },
};
const mutations = {
  SET_ARTICLE_DATA: (state, data) => {
    state.articles.data = data.data;
    state.articles.pagination = data.meta;
  },

  SET_ARTICLE_PROFILE: (state, data) => (state.article = data),

  SET_ArticleLIST: (state, data) => (state.articleList = data),
  //SET_EMPLOYEE_DATA: (state, data) => (state.employees = data),
  SET_REPORT_DATA: (state, data) => (state.reportData = data),

  ADD_ARTICLE: (state, data) => state.articles.data.push(data),

  DELETE_ARTICLE: (state, data) => {
    const i = state.articles.data.map((item) => item.id).indexOf(data.id);
    state.articles.data.splice(i, 1);
  },
  EDIT_ARTICLE_DATA: (state, data) => {
    const i = state.articles.data.map((item) => item.id).indexOf(data.id);
    state.articles.data[i].id = data.id;
    state.articles.data[i].title = data.title;
    state.articles.data[i].content = data.content;
    state.articles.data[i].approved = data.approved;
    state.articles.data[i].created_at = data.created_at;
    state.articles.data[i].category_name = data.category_name;
    state.articles.data[i].english = data.english;
    if (data.images[0]) {
      state.articles.data[i].images[0] = data.images[0];
    }
    state.articles.data[i].tags = data.tags;
  },
  EDIT_ARTICLE_VENDORS_DATA: (state, data) => {
    const i = state.articles.data.map((item) => item.id).indexOf(data.id);
    state.articles.data[i].id = data.id;

    state.articles.data[i].vendors = data.vendors;
  },

  SET_ARTICLE_CURRENT_PAGE: (state, data) =>
    (state.articles.pagination.current_page = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
