<template>
  <div class="text-right">
    <v-snackbar
      v-model="snackbarVisible"
      :timeout="timeout"
      :color="type"
      top
      left
      variant="tonal"
    >
      <!-- <v-btn text @click="closeSnackbar" class="close-button">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn> -->
      <span class="ms-2"
        ><v-icon>{{ iconSelector() }}</v-icon
        ><span class="ms-2 me-2">{{ text }}</span></span
      >
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    timeout: {
      type: Number,
      default: 6000,
    },
    type: {
      type: String,
      default: "info",
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    snackbarVisible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeSnackbar() {
      this.$emit("close-snackbar");
    },
    iconSelector() {
      if (this.type == "success") {
        return "mdi-check-circle-outline";
      }
      if (this.type == "error") {
        return "mdi-close-circle-outline";
      }
    },
  },
};
</script>

<style scoped>
.close-button {
  margin-left: auto;
  margin-right: auto;
}
</style>
