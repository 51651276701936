<template>
  <v-dialog
    v-if="SystemLogDetailsDialog && currentLog"
    :value="SystemLogDetailsDialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card v-click-outside="handleClickOutside">
      <v-toolbar light class="mb-5">
        <v-btn icon light @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> Log details \ {{ logDetails }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text @click="closeDialog"> Close</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card elevation="0">
        <v-card-text>
          <!-- Display the logs as a list -->
          <v-list>
            <v-list-item-group v-if="allLogDetails && allLogDetails.length">
              <v-list-item v-for="(log, index) in allLogDetails" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ log }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title>No logs available</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  label: "EditLogDetails",

  props: ["SystemLogDetailsDialog", "currentLog", "logDetails"],
  components: {},
  computed: {
    ...mapGetters(["authenticated", "allLogDetails"]),
  },
  data() {
    return {
      userDisabled: false,
      isBuyBox: false,
      LogData: Object.assign({}, this.currentLog),
      requiredRule: [(v) => !!v || "This field is required"],
      requiredRule2: [(v) => v.length > 0 || "This field is required"],
      loadingSpinner: false,
      loadingSpinner2: false,
      loadingSpinner3: false,
      alrtState: false,
      alrtText: null,
      snackbar: {
        show: false,
        timeout: 6000,
        type: "info",
        text: "",
      },
    };
  },

  watch: {
    currentLog(newVal) {
      this.LogData = Object.assign({}, newVal);
      this.$set(this, "LogData", Object.assign({}, newVal));
    },
  },

  created() {},
  methods: {
    ...mapActions(["fetchSysLogByName"]),

    closeDialog() {
      this.$emit("SystemLogDialogClose", false);
    },
    handleClickOutside() {},

    showSnackbar() {
      this.snackbar.show = true;
    },
    updateSnackbar(value) {
      this.snackbar.show = value;
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
.image-container {
  position: relative;
}

.remove-btn {
  top: 0px;
  right: 0px;
  border: 1px solid #000 !important;
}

.bordered {
  border: 1px solid #000;
}
</style>
