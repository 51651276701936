<template>
  <div dir="rtl">
    <UpperDivision />
    <div class="main-container">
      <v-container :fluid="fluidState" class="mt-3">
        <v-row>
          <v-col
            cols="2"
            md="2"
            lg="2"
            class="pt-1 sidebar-custom"
            v-show="this.$vuetify.breakpoint.mdAndUp"
          >
            <Sidebar />
          </v-col>
          <v-col cols="12" md="10" sm="12" lg="10" class="contain-body">
            <router-view />
            <Footer />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import UpperDivision from "../../components/control/UpperDivision";
import Footer from "../../components/control/Footer";
import Sidebar from "../../components/control/Sidebar.vue";
export default {
  name: "contentPage",
  components: {
    UpperDivision,
    Footer,
    Sidebar,
  },
  data() {
    return {
      fluidState: "",
      currentRoute: localStorage.getItem("current-path") || "",
    };
  },
  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.currentRoute = localStorage.getItem("current-path");
      }
    },
  },

  created() {
    this.screenCheck();
  },
  mounted() {
    this.screenCheck;
  },
  methods: {
    screenCheck() {
      let windowWidth = window.innerWidth;
      if (windowWidth.value < 550) this.fluidState = true;
      if (windowWidth.value > 549 && windowWidth.value < 1200)
        this.fluidState = true;
      if (windowWidth.value > 1199) this.fluidState = false;
    },
  },
};
</script>

<style lang="scss" scopped>
.sidebar-custom {
  height: 94vh !important;
  background-color: #fafafa !important;
}

.contain-body {
  background-color: #ffffff !important;
  height: 85vh !important;
  overflow-y: auto !important;
}
@media only screen and (max-width: 600px) {
  .appbar-hight {
    height: 22vh !important;
  }
  .contain-body {
    height: 89vh !important;
    overflow-y: auto !important;
  }

  .footer-height {
    height: 5vh !important;
  }
}
</style>
