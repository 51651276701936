<template>
  <div class="home-page small-text" v-if="getReportData">
    <v-form @submit.prevent="submitReportForm">
      <v-row>
        <!-- From Date -->
        <v-col cols="12" md="4">
          <v-text-field
            label="من"
            v-model="form.from"
            outlined
            type="date"
            :rules="[(v) => !!v || 'من فضلك أدخل التاريخ']"
          />
        </v-col>

        <!-- To Date -->
        <v-col cols="12" md="4">
          <v-text-field
            label="إلى"
            v-model="form.to"
            outlined
            type="date"
            :rules="[(v) => !!v || 'من فضلك أدخل التاريخ']"
          />
        </v-col>

        <!-- Submit Button -->
        <v-col cols="12" md="4" class="">
          <v-btn color="primary" elevation="0" type="submit">فلترة</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" md="4">
        <v-card elevation="0" class="blue lighten-5 pb-2">
          <v-card-title class="pb-1"
            ><v-icon class="ms-2">mdi-social-distance-2-meters</v-icon>
            المنشورات
          </v-card-title>
          <v-card-text class="py-2"
            >المنشورات الكلية :
            {{ getReportData.articles_statistics.total_articles }}</v-card-text
          >
          <v-card-text class="py-2"
            >المنشورات الفعالة :
            {{
              getReportData.articles_statistics.published_articles
            }}</v-card-text
          >

          <v-card-text class="py-2"
            >المنشورات غير المكتملة :
            {{ getReportData.articles_statistics.draft_articles }}</v-card-text
          >

          <v-card-text class="py-2"
            >المنشورات غير الغعالة :
            {{
              getReportData.articles_statistics.archived_articles
            }}</v-card-text
          >
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card elevation="0" class="grey lighten-4 pb-2">
          <v-card-title class="pb-1"
            ><v-icon class="ms-2">mdi-account-group-outline</v-icon> حسابات
            المستخدمين
          </v-card-title>
          <v-card-text class="py-2"
            >الحسابات الكلية :
            {{ getReportData.users_statistics.total_users }}</v-card-text
          >

          <v-card-text class="py-2"
            >الحسابات الفعالة :
            {{ getReportData.users_statistics.active_users }}</v-card-text
          >

          <v-card-text class="py-2"
            >الحسابات غير الفعالة :
            {{ getReportData.users_statistics.suspended_users }}</v-card-text
          >
          <v-card-text class="py-2"
            >الحسابات الموقوفة :
            {{ getReportData.users_statistics.banned_users }}</v-card-text
          >
        </v-card>
      </v-col>
      <!--  <v-col cols="12" md="4">
        <v-card elevation="0" class="green lighten-5 pb-2">
          <v-card-title class="pb-1"
            ><v-icon class="ms-2">mdi-chart-bar</v-icon> احصائات المنصة
          </v-card-title>
          <v-card-text class="py-2">الزيارات الكلية : 00</v-card-text>

          <v-card-text class="py-2">الزيارات الشهرية : 00</v-card-text>

          <v-card-text class="py-2">الزيارات لهذا اليوم : 00</v-card-text>
        </v-card>
      </v-col> 

      <v-col cols="12" md="4">
        <v-card elevation="0" class="yellow lighten-5 pb-2">
          <v-card-title class="pb-1"
            ><v-icon class="ms-2">mdi-data-matrix-scan</v-icon> النشاطات
          </v-card-title>
          <v-card-text class="py-2">عدد النشاطات الكلية : 00</v-card-text>
          <v-card-text class="py-2">عدد نشاطات الحساب : 00</v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
    <loading :isLoading="loading"></loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DashboardPage",
  data() {
    return {
      loading: false,
      form: {
        from: "", // Store the 'from' date
        to: "", // Store the 'to' date
      },
    };
  },
  computed: {
    ...mapGetters(["getReportData"]),
  },

  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.loading = true;
        this.fetchReportData()
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },

  created() {
    this.loading = true;

    this.fetchReportData()
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  methods: {
    ...mapActions(["fetchReportData"]),

    // Handle form submission
    submitReportForm() {
      // Ensure both 'from' and 'to' dates are filled
      if (this.form.from && this.form.to) {
        this.loading = true;
        const formData = {
          from: this.form.from,
          to: this.form.to,
        };

        this.fetchReportData(formData) // Pass the form data
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        // Show an error message if dates are missing
        alert("من فضلك أدخل كلا التاريخين");
      }
    },
  },
};
</script>
