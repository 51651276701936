import axios from "@/axios";

const state = {
  institutes: {
    data: [],
  },
};

const getters = {
  allInstitutes: (state) => state.institutes.data,
  getInstitute: (state) => (id) => {
    return state.institutes.data.find((institutes) => institutes.id === id);
  },
};
const actions = {
  async fetchInstitutes({ commit }) {
    const response = await axios.get(
      "/" + this.getters["getRole"] + "/institutes"
    );
    commit("SET_INSTITUTE_DATA", response.data);
  },

  async addInstituteFunc({ commit }, info) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/institutes",
      info
    );
    if (response.status == 201) {
      commit("ADD_INSTITUTE", response.data.data);
      return "success";
    } else return "failed";
  },

  async editInstituteFunc(_, info) {
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/institutes/" + info.get("id"),
      info
    );
    console.log(response);
    if (response.status == 200) return "success";
    else return "error";
    //commit("EDIT_INSTITUTE_DATA", response.data.category);
  },

  async deleteInstituteFunc({ commit }, info) {
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/institutes/" + info.id,
      {
        _method: "delete",
      }
    );
    if (response.status == 204) commit("DELETE_INSTITUTE", info);
    return response.data;
  },
};

const mutations = {
  SET_INSTITUTE_DATA: (state, data) => {
    state.institutes.data = data.data;
  },
  ADD_INSTITUTE: (state, data) => state.institutes.data.push(data),
  DELETE_INSTITUTE: (state, data) => {
    const i = state.institutes.data.map((item) => item.id).indexOf(data.id);
    state.institutes.data.splice(i, 1);
  },
  EDIT_INSTITUTE_DATA: (state, data) => {
    const i = state.institutes.data.map((item) => item.id).indexOf(data.id);
    state.institutes.data[i].name = data.name;
    state.institutes.data[i].children = data.children;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
