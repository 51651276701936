<template>
  <div class="text-right" dir="rtl">
    <v-dialog v-model="dialog" width="500">
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="blue"
          elevation="0"
          class="small-text white--text fa-12"
        >
          <v-icon right dark class="me-2"> mdi-book-edit-outline </v-icon>
          <span>تعديل كلمة السر</span>
        </v-btn>
      </template>
      <v-form ref="changePasswordForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4">
            <span class=""
              ><v-icon>mdi-alert-circle-outline</v-icon> Change Password</span
            >
          </v-card-title>

          <v-col cols="12" class="rounded mt-2">
            <span class="d-block">
              Please fill in the information for the old and new password to
              change your account password.</span
            >
          </v-col>

          <v-container>
            <h6>Old Password</h6>
            <v-text-field
              v-model="current_password"
              placeholder="Old password"
              outlined
              dense
              :rules="requiredRule"
              class="mt-2"
              type="password"
            ></v-text-field>
            <h6>New Password</h6>
            <v-text-field
              v-model="new_password"
              placeholder="New password"
              outlined
              dense
              :rules="requiredRule"
              class="mt-2"
              type="password"
            ></v-text-field>
            <h6>Confirm Password</h6>
            <v-text-field
              v-model="new_password_confirmation"
              placeholder="Confirm password"
              outlined
              dense
              :rules="requiredRule"
              class="mt-2"
              type="password"
            ></v-text-field>
          </v-container>

          <v-card-actions>
            <SnackbarComponent
              :show="snackbar.show"
              :timeout="snackbar.timeout"
              :type="snackbar.type"
              :text="snackbar.text"
              @update:show="updateSnackbar"
              @close-snackbar="closeSnackbar"
            />

            <v-btn
              :loading="loadingSpinner"
              :disabled="loadingSpinner"
              color="primary"
              text
              @click="ChangePasswordFunction"
            >
              Change Password
            </v-btn>
            <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

export default {
  components: {
    SnackbarComponent,
  },
  data() {
    return {
      loadingSpinner: false,
      dialog: false,
      requiredRule: [(v) => !!v || "This field is required"],
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
      info: new FormData(),
      alrtState: false,
      alrtType: "info",
      alrtText: null,
      valid: false,
      snackbar: {
        show: false,
        timeout: 6000,
        type: "info",
        text: "",
      },
    };
  },
  methods: {
    ...mapActions(["changePassword"]),
    ChangePasswordFunction() {
      if (!this.$refs.changePasswordForm.validate()) return;
      if (this.new_password.length < 6) {
        this.alrtText = "Password must be at least 6 characters long";
        this.snackbar.show = true;
        this.snackbar.text = "Password must be at least 6 characters long";
        this.snackbar.type = "warning";
        return;
      } else if (this.new_password_confirmation != this.new_password) {
        this.snackbar.show = true;
        this.snackbar.text = "Password and Confirm Password do not match";
        this.snackbar.type = "warning";
        return;
      } else if (this.current_password == this.new_password) {
        this.snackbar.show = true;
        this.snackbar.text = "Old password and new password must be different";
        this.snackbar.type = "warning";
        return;
      } else {
        this.info.append("current_password", this.current_password);
        this.info.append("new_password", this.new_password);
        this.info.append(
          "new_password_confirmation",
          this.new_password_confirmation
        );
        this.loadingSpinner = true;
        this.changePassword(this.info)
          .then(() => {
            this.snackbar.show = true;
            this.snackbar.text = "Password has been reseted successfully";
            this.snackbar.type = "success";
            setTimeout(() => {
              this.snackbar.show = false;
              this.loadingSpinner = false;
              this.$refs.changePasswordForm.reset();
              this.info = new FormData();
              this.dialog = false;
              this.valid = false;
            }, 3000);
          })
          .catch((err) => {
            console.error(err);
            this.loadingSpinner = false;
            this.snackbar.show = true;
            this.snackbar.text =
              "An Error occured:" + err.response.data.message;
            this.snackbar.type = "error";
          });
      }
    },

    showSnackbar() {
      this.snackbar.show = true;
    },
    updateSnackbar(value) {
      this.snackbar.show = value;
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
  },
};
</script>

<style>
v-text-field {
  direction: ltr !important;
}

.eng-text {
  font-family: sans-serif !important;
  color: gray;
}
</style>
