<template>
  <div>
    <v-list dense right class="mt-0 transparent" dir="rtl">
      <v-list-item-group v-model="selectedItem">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          light
          active-class="highlighted"
          :class="
            item.route === $route.path
              ? 'mb-2 hovered-white undecoratedLink highlighted'
              : 'mb-2 hovered-white undecoratedLink '
          "
          @click="routeLink(item.route, item.linkName)"
        >
          <v-icon color="#78909c" light small>{{ item.icon }}</v-icon>
          <v-list-item-content>
            <v-list-item-title class="ps-2 pe-2 d-none d-md-block">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
          <!--      <v-list-item-icon></v-list-item-icon> -->
        </v-list-item>
        <v-list-item @click="handleSignOut">
          <v-list-item-content>
            <v-list-item-title class="d-none d-md-block fa-13">
              <v-icon color="#78909c" light small>mdi-logout</v-icon> تسجيل
              الخروج</v-list-item-title
            >
          </v-list-item-content>
          <!--      <v-list-item-icon></v-list-item-icon> -->
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<style lang="scss" scopped>
.list-item-style {
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppSideBar",
  role: "",

  data() {
    return {
      drawer: true,
      selectedItem: 0,
      items: [],
      mini: false,
      role: "user",
    };
  },

  computed: {
    ...mapGetters(["authenticated"]),
  },

  created() {
    this.roleCheck();
    this.role = this.authenticated.role;
    this.updateSelectedItem();
    this.$watch("$route", this.updateSelectedItem);

    //this.roleCheck(this.role);
  },

  methods: {
    ...mapActions(["signout"]),

    handleSignOut() {
      this.$store.dispatch("signout").then(() => {
        this.$router.push({ name: "LoginPage" });
      });
    },

    routeLink(route, linkName) {
      if (this.$route.path === route) {
        return;
      }
      this.$globalLink = linkName;
      localStorage.setItem("link", linkName);
      this.$cookies.set("link", linkName);

      let parameters = [linkName, route];
      Event.$emit("pathChanger", parameters);

      this.$router.push(route);
    },
    updateSelectedItem() {
      const foundItem = this.items.find(
        (item) => item.route === this.$route.path
      );
      if (foundItem) {
        this.selectedItem = this.items.indexOf(foundItem);
      }
    },

    roleCheck() {
      if (
        this.authenticated.role === "administrator" ||
        this.authenticated.role === "developer"
      ) {
        this.items = [
          {
            title: "الصفحة الرئيسية",
            icon: "mdi-home",
            route: "/control-panel/dashboard",
            linkName: "Home",
          },

          {
            title: "مدير المنشورات",
            icon: "mdi-newspaper",
            route: "/control-panel/post-manager",
            linkName: "Mailbox",
          },

          /*    {
            title: "دليل المستخدم",
            icon: "mdi-video",
            route: "/control-panel/user-guide",
            linkName: "User Guide",
          }, */
          {
            title: "التصنيفات",
            icon: "mdi-cogs",
            route: "/control-panel/categories",
            linkName: "Categories",
          },
          {
            title: "الجهات المشاركة",
            icon: "mdi-account-multiple-check-outline",
            route: "/control-panel/institutes",
            linkName: "Institutes",
          },
          {
            title: "سجل حركات النظام",
            icon: "mdi-file-excel-box-outline",
            route: "/control-panel/log-manager",
            linkName: "Log Manager",
          },

          {
            title: " ادارة الحسابات",
            icon: "mdi-account-badge-outline",
            route: "/control-panel/account-manager",
            linkName: "Account Manager",
          },

          {
            title: "اعدادات الحساب",
            icon: "mdi-account-circle-outline",
            route: "/control-panel/user-page",
            linkName: "Account Setting",
          },
        ];
      } else {
        this.items = [
          {
            title: "الصفحة الرئيسية",
            icon: "mdi-home",
            route: "/control-panel/dashboard",
            linkName: "Home",
          },

          {
            title: "مدير المنشورات",
            icon: "mdi-newspaper",
            route: "/control-panel/post-manager",
            linkName: "Mailbox",
          },

          /*    {
            title: "دليل المستخدم",
            icon: "mdi-video",
            route: "/control-panel/user-guide",
            linkName: "User Guide",
          }, */

          {
            title: "اعدادات الحساب",
            icon: "mdi-account-circle-outline",
            route: "/control-panel/user-page",
            linkName: "Account Setting",
          },
        ];
      }
    },
  },
};
</script>
