<template>
  <div class="home-page small-text">
    <v-card-text class="pa-0 mb-4">
      <v-row>
        <v-col cols="12" md="4">انشطة النظام</v-col>
      </v-row>
    </v-card-text>

    <v-card elevation="0">
      <v-card-text>
        <v-data-table
          align-center
          :headers="headers"
          :items="allSysLog"
          hide-default-footer
          fixed-header
          :options="{ itemsPerPage: -1 }"
          class="pointer-text"
        >
          <template #[`item.filename`]="{ item }">
            {{ item }}
          </template>
          <template #[`item.options`]="{ item }">
            <v-btn
              elevation="0"
              class="blue small-text white--text lighten-2"
              @click="showLogDetails(item)"
            >
              <v-icon small class="me-1">mdi-file-account-outline</v-icon>
              التفاصيل
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <SystemLogDetailsDialog
      :SystemLogDetailsDialog="SystemLogDetailsDialog"
      :currentLog="systemLogData"
      :logDetails="logDetailsItem"
      @SystemLogDialogClose="SystemLogDetailsDialog = $event"
    />
    <loading :isLoading="loading"></loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "@/components/LoadingScreen.vue";
import SystemLogDetailsDialog from "@/components/control/log/LogDetailsDialog.vue";

export default {
  name: "SystemLogPage",
  components: {
    Loading,
    SystemLogDetailsDialog,
  },
  data() {
    return {
      searchField: null,
      currentLog: null,
      loading: false,
      addSystemLogDialog: false,
      headers: [
        { text: "Filename", value: "filename" },
        { text: "Options", value: "options" },
      ],
      SystemLogDetailsDialog: false,
      systemLogData: null,
      logDetailsItem: false,
      disablePagination: false,
      search: null,
      info: {
        page: 1,
        keyword: null,
      },
      interval: null,
    };
  },
  computed: {
    ...mapGetters(["authenticated", "allSysLog", "getRole", "getUser"]),
  },

  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.info.page = newVal;
        this.info.keyword = this.search;
        this.disablePagination = true;
        this.loading = true;
        this.fetchSystemLog(this.info)
          .then(() => {
            this.loading = false;
            this.disablePagination = false;
          })
          .catch(() => {
            this.loading = false;
            this.disablePagination = false;
          });
      }
    },
  },

  created() {
    this.disablePagination = true;
    this.loading = true;
    this.fetchSystemLog(this.info)
      .then(() => {
        this.loading = false;
        this.disablePagination = false;
      })
      .catch(() => {
        this.loading = false;
        this.disablePagination = false;
      });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions(["fetchSystemLog", "fetchSysLogByName"]),

    checkRole() {
      if (this.authenticated.role == "administrator") {
        this.headers = [
          { text: "Filename", value: "filename" },
          { text: "Options", value: "options" },
        ];
      } else if (this.authenticated.role == "vendor") {
        this.headers = [{ text: "Filename", value: "filename" }];
      }
    },

    searchInTable() {
      this.info.page = 1;
      this.info.keyword = this.searchField;
      this.loading = true;
      this.fetchSystemLog(this.info)
        .then(() => {
          this.loading = false;
          this.disablePagination = false;
        })
        .catch(() => {
          this.loading = false;
          this.disablePagination = false;
        });
    },

    showLogDetails(item) {
      this.systemLogData = this.fetchSysLogByName(item);
      //counters
      this.logDetailsItem = item;
      this.SystemLogDetailsDialog = true;
    },
  },
};
</script>
