import axios from "@/axios";

const state = {
  categories: {
    data: [],
  },
  flattenCategoriesData: {
    data: [],
  },
};

const getters = {
  //normal categories group
  allCategories: (state) => state.categories.data,
  getCategory: (state) => (id) => {
    return state.categories.data.find((categories) => categories.id === id);
  },
  //flattened categories group
  allFlattenedCategories: (state) => state.flattenCategoriesData.data,
  getFlattenedCategory: (state) => (id) => {
    return state.flattenCategoriesData.data.find(
      (category) => category.id === id
    );
  },
};
const actions = {
  async fetchCategories({ commit }) {
    console.log(this.getters);
    const response = await axios.get(
      "/" + this.getters["getRole"] + "/categories"
    );
    commit("SET_CATEGORY_DATA", response.data);
  },

  async fetchFlattenedCategories({ commit }) {
    const response = await axios.get(
      "/" + this.getters["getRole"] + "/categories"
    );
    commit("SET_FLATTENED_CATEGORY_DATA", response.data);
  },

  // fetch limited product
  async fetchLimitedCategories({ commit }, limit) {
    const response = await axios.get(
      "/" + this.getters["getRole"] + "/categories?limit=" + limit
    );
    commit("SET_CATEGORY_DATA", response.data);
  },

  // fetch limited product
  async fetchCategorytBySlug({ commit }, slug) {
    try {
      const response = await axios.get(
        "/" + this.getters["getRole"] + "/categories/" + slug
      );
      if (response.status === 200) {
        commit("SET_CATEGORY_PROFILE", response.data.data[0]);
      } else {
        throw new Error("Failed to fetch product");
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  //EDIT CATEGORIES
  async editCategoryFunc(_, info) {
    console.log(info);

    const response = await axios.patch(
      "/" + this.getters["getRole"] + "/categories/" + info.id,
      info
    );
    if (response.status == 200) return "success";
    else return "error";
    //commit("EDIT_CATEGORY_DATA", response.data.category);
  },

  async addCategoryFunc({ commit }, info) {
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/categories",
      info
    );
    console.log("Catgs are:", response.data.data);

    if (response.status == 201) {
      commit("ADD_CATEGORY", response.data.data);
      return "success";
    } else return "failed";
  },

  async deleteCategoryFunc({ commit }, info) {
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/categories/" + info.id,
      {
        _method: "delete",
      }
    );
    if (response.status == 204) commit("DELETE_CATEGORY", info);
    return response.data;
  },

  setCategoryCurrentPage({ commit }, currentPage) {
    commit("SET_CATEGORY_CURRENT_PAGE", currentPage);
  },

  setFlattenedCategoryCurrentPage({ commit }, currentPage) {
    commit("SET_FLATTENED_CATEGORY_CURRENT_PAGE", currentPage);
  },
};

// Define the helper function to flatten categories
const flattenCategories = (categories, parent = null) => {
  const flatCategories = [];

  const flatten = (category, parent) => {
    // Create a new category object without children
    const { children, ...flatCategory } = category;
    flatCategory.parent = parent; // Add the parent ID

    // Push the flattened category into the array
    flatCategories.push(flatCategory);

    // Recursively flatten the children
    if (children && children.length > 0) {
      children.forEach((child) => {
        flatten(child, category.id);
      });
    }
  };

  categories.forEach((category) => {
    flatten(category, parent);
  });

  return flatCategories;
};

const mutations = {
  SET_CATEGORY_DATA: (state, data) => {
    state.categories.data = data.data;
  },
  SET_FLATTENED_CATEGORY_DATA: (state, data) => {
    console.log("catgs are:", data.data);
    state.flattenCategoriesData.data = flattenCategories(data.data);
  },

  SET_CATEGORY_PROFILE: (state, data) => (state.product = data),

  SET_CategoryLIST: (state, data) => (state.productList = data),
  //SET_EMPLOYEE_DATA: (state, data) => (state.employees = data),
  SET_EXPORT_DATA: (state, data) => (state.reportData = data),

  ADD_CATEGORY: (state, data) => state.categories.data.push(data),

  DELETE_CATEGORY: (state, data) => {
    const i = state.categories.data.map((item) => item.id).indexOf(data.id);
    state.categories.data.splice(i, 1);
  },
  EDIT_CATEGORY_DATA: (state, data) => {
    const i = state.categories.data.map((item) => item.id).indexOf(data.id);
    state.categories.data[i].name = data.name;
    state.categories.data[i].children = data.children;
  },

  SET_CATEGORY_CURRENT_PAGE: (state, data) =>
    (state.categories.pagination.current_page = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
