import axios from "@/axios";

const state = {
  sysLog: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },
  logDetails: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },
};

const getters = {
  allSysLog: (state) => state.sysLog.data,
  allLogDetails: (state) => state.logDetails.data,
  getSysLog: (state) => (id) => {
    return state.sysLog.data.find((sysLog) => sysLog.id === id);
  },
  getSysLogCurrentPage: (state) =>
    state.sysLog.pagination ? state.sysLog.pagination.current_page : 1,
  getSysLogLastPage: (state) =>
    state.sysLog.pagination ? state.sysLog.pagination.total_pages : 1,
};
const actions = {
  async fetchSystemLog({ commit }, info) {
    var page = info.page;
    var keyword = info.keyword;
    var perPage = info.perPage ? info.perPage : 16;

    if (keyword != "" && keyword != null) {
      const response = await axios.get(
        "/" +
          this.getters["getRole"] +
          "/logs/files?per_page=" +
          perPage +
          "&page=" +
          page +
          "&keyword=" +
          keyword
      );
      commit("SET_SYS_LOG_DATA", response.data);
    } else {
      const response = await axios.get(
        "/" +
          this.getters["getRole"] +
          "/logs/files?per_page=" +
          perPage +
          "&page=" +
          page
      );
      commit("SET_SYS_LOG_DATA", response.data);
    }
  },

  // fetch limited product
  async fetchSysLogByName({ commit }, info) {
    try {
      const parameters = {
        log_file: info,
      };
      const response = await axios.get(
        `/${this.getters["getRole"]}/logs/content/`,
        {
          params: parameters,
        }
      );

      if (response.status === 200) {
        commit("SET_LOG_DETAILS", response.data);
      } else {
        throw new Error("Failed to fetch product");
      }
    } catch (error) {
      throw error;
    }
  },

  //EDIT VENDORS
  async editSysLog({ commit }, info) {
    const response = await axios.patch(
      "/" + this.getters["getRole"] + "/vendorDashboardSysLogs/" + info.id,
      info
    );
    if (response.status == 200)
      commit("EDIT_SYS_LOG_DATA", response.data.notification);
  },

  async addSysLogFunc({ commit }, SysLog) {
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/vendorDashboardSysLogs",
      SysLog
    );
    if (response.status == 201) {
      commit("ADD_SYS_LOG", response.data.notification);

      return "success";
    } else return "failed";
  },

  async deleteSysLog({ commit }, info) {
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/searchHistories/" + info.id,
      {
        _method: "delete",
      }
    );
    if (response.status == 200) commit("DELETE_SYS_LOG", info);
    return response.data;
  },

  setSysLogCurrentPage({ commit }, currentPage) {
    commit("SET_SYS_LOG_CURRENT_PAGE", currentPage);
  },
};
const mutations = {
  SET_SYS_LOG_DATA: (state, data) => {
    state.sysLog.data = data.data;
  },
  SET_LOG_DETAILS: (state, data) => {
    state.logDetails.data = data.data;
  },

  SET_SYS_LOG_PROFILE: (state, data) => (state.product = data),

  SET_SYS_LOGLIST: (state, data) => (state.productList = data),

  ADD_SYS_LOG: (state, data) => state.sysLog.data.push(data),

  DELETE_SYS_LOG: (state, data) => {
    const i = state.sysLog.data.map((item) => item.id).indexOf(data.id);
    state.sysLog.data.splice(i, 1);
  },
  EDIT_SYS_LOG_DATA: (state, data) => {
    const i = state.sysLog.data.map((item) => item.id).indexOf(data.id);
    state.sysLog.data[i].name = data.name;
  },

  SET_SYS_LOG_CURRENT_PAGE: (state, data) =>
    (state.sysLog.pagination.current_page = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
