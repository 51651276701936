<template>
  <div>
    <v-dialog
      v-if="EditArticleDialog && currentArticle"
      v-model="EditArticleDialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
      @click:outside="handleClickOutside"
      ref="dialog"
    >
      <v-card ref="dialogCard" dir="rtl">
        <v-toolbar light class="mb-5">
          <v-btn icon light @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> تفاصيل المنشور </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text class="red-panel" @click="confirmDelete"
              ><v-icon>mdi-delete-circle-outline</v-icon> حذف المنشور</v-btn
            >
            <v-btn text @click="closeDialog"> Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form ref="product_details">
              <v-row>
                <v-col cols="12" class="blue lighten-5 rounded mb-1">
                  <span class="d-block"
                    ><v-icon>mdi-alert-circle-outline</v-icon> الرجاء مليء جميع
                    الحقول المطلوبة لغرض تسجيل البيانات ضمن المنصة وباللغتين
                    العربية والانكليزية
                  </span>
                </v-col>
                <v-col cols="12" class="">
                  <v-row>
                    <v-col cols="12" md="4" lg="4" xl="4" xxl="4" class="pb-0">
                      <v-autocomplete
                        v-model="articleData.category_id"
                        :items="allFlattenedCategories"
                        item-value="id"
                        outlined
                        required
                        clearable
                        :rules="requiredRule"
                        :item-text="textFormat"
                        placeholder="التصنيف"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" xl="4" xxl="4" class="pb-0">
                      <v-radio-group
                        v-model="articleData.status"
                        @change="handleStatusChange"
                        hide-details
                        row
                      >
                        <v-radio value="published" color="success">
                          <template #label>
                            <span class="radio-label">فعال</span>
                          </template>
                        </v-radio>
                        <v-radio value="archived" color="warning">
                          <template #label>
                            <span class="radio-label">ارشيف</span>
                          </template>
                        </v-radio>
                        <v-radio value="draft" color="info">
                          <template #label>
                            <span class="radio-label">مسودة</span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="selectedTag"
                        :items="availableTags"
                        item-text="text"
                        item-value="value"
                        placeholder="اختيار فئة المنشور"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-btn
                        @click="addTag"
                        elevation="0"
                        class="blue lighten-1 white--text my-3"
                      >
                        <v-icon>mdi-plus-circle-outline</v-icon> اضافة
                        فئة</v-btn
                      >
                    </v-col>

                    <v-col cols="12" md="4">
                      <div class="selected-tags my-3">
                        <v-chip
                          dir="ltr"
                          v-for="(tag, index) in articleData.tags"
                          :key="index"
                          close
                          @click:close="removeTag(index)"
                          class="blue white--text mx-1 mt-2"
                        >
                          {{ tag }}
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6" class="py-0">
                  <v-col cols="12" class="white mb-2">
                    <span class="d-block fa-16"
                      ><v-icon>mdi-alert-circle-outline</v-icon> البيانات باللغة
                      العربية
                    </span>
                  </v-col>
                  <v-col cols="12" md="12" lg="12" xl="12" xxl="12" class="">
                    <v-text-field
                      v-model="articleData.title_arabic"
                      type="text"
                      placeholder="عنوان المنشور"
                      outlined
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" lg="12" xl="12" xxl="12">
                    <v-textarea
                      v-model="articleData.content_arabic"
                      placeholder="تفاصيل المنشور"
                      outlined
                      :rules="requiredRule"
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-col>

                <!--English Data Section-->
                <v-col cols="6" dir="ltr" class="py-0">
                  <v-col cols="12" class="white mb-2">
                    <span class="d-block fa-16"
                      ><v-icon>mdi-alert-circle-outline</v-icon>
                      Post data in English
                    </span>
                  </v-col>
                  <v-col cols="12" md="12" lg="12" xl="12" xxl="12" class="">
                    <v-text-field
                      v-model="articleData.title_english"
                      type="text"
                      placeholder="Title"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" lg="12" xl="12" xxl="12">
                    <v-textarea
                      v-model="articleData.content_english"
                      placeholder="Post Details"
                      outlined
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="pb-4 mb-5 mt-5 blue lighten-5 rounded">
                  <h3>صور المنشورات</h3>
                </v-col>

                <v-col cols="12">
                  <v-alert
                    border="top"
                    type="warning"
                    variant="outlined"
                    prominent
                  >
                    الرجاء التأكد من الصور المرفوعة حيث ان الصور القديمة سوف يتم
                    حذفها عند التعديل
                  </v-alert>
                </v-col>

                <v-col
                  v-for="(image, index) in articleData.images"
                  :key="index"
                  cols="12"
                  md="2"
                >
                  <div class="image-container">
                    <div
                      class="d-block green-panel px-2 py-1 my-2 rounded"
                      v-if="image.type == 'original'"
                    >
                      <v-icon>mdi-check-circle-outline</v-icon>
                      مؤكد
                    </div>
                    <div
                      class="d-block red-panel px-2 py-1 my-2 rounded"
                      v-if="image.type == 'fake'"
                    >
                      <v-icon>mdi-allergy</v-icon>
                      مزيف
                    </div>
                    <div class="bordered py-2">
                      <v-img
                        width="80%"
                        height="auto"
                        :src="image.image_path"
                        class="mx-auto"
                      ></v-img>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  v-for="(image, index) in newImages"
                  :key="'new-' + index"
                  cols="12"
                >
                  <v-row>
                    <v-col cols="4">
                      <v-file-input
                        v-model="image.file"
                        label="Choose an image"
                        outlined
                        accept="image/*"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="image.type"
                        :items="['original', 'fake']"
                        outlined
                        label="Image Type"
                      ></v-select>
                    </v-col>
                    <v-col cols="4" class="mt-3">
                      <v-btn
                        @click="removeNewImage(index)"
                        color="error"
                        elevation="0"
                        class="lighten-2 small-text"
                      >
                        <v-icon>mdi-delete</v-icon> حذف صورة
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    @click="addNewImage"
                    class="blue lighten-1 white--text"
                    elevation="0"
                  >
                    <v-icon>mdi-plus</v-icon> اضافة صورة
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mx-auto text-right">
                  <SnackbarComponent
                    :show="snackbar.show"
                    :timeout="snackbar.timeout"
                    :type="snackbar.type"
                    :text="snackbar.text"
                    @update:show="updateSnackbar"
                    @close-snackbar="closeSnackbar"
                  />
                  <v-btn
                    :loading="loadingSpinner"
                    :disabled="loadingSpinner"
                    color="primary"
                    @click="editArticleFunc"
                    ><v-icon>mdi-pencil-circle-outline</v-icon> Edit Main
                    Data</v-btn
                  >
                  <v-btn color="primary" text @click="closeDialog">
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--Delete dialog confirmation-->
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card dir="rtl">
        <v-card-title class="bg-red"
          ><v-icon color="white" class="mx-2">mdi-delete-circle-outline</v-icon>
          تاكيد الحذف</v-card-title
        >
        <v-card-text class="py-4">هل انت متأكد من حذف المنشور؟</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false"
            >اغلاق</v-btn
          >
          <v-btn class="bg-red px-2" text @click="deleteArticleFunc">
            حذف</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SnackbarComponent from "@/components/SnackbarComponent.vue";
export default {
  label: "EditArticleDetails",

  props: ["EditArticleDialog", "currentArticle"],
  components: {
    SnackbarComponent,
  },
  computed: {
    ...mapGetters(["allCategories", "allFlattenedCategories"]),
  },
  data() {
    return {
      approved: false,
      approvedVal: null,
      articleData: {},
      newImages: [],
      loadingSpinner: false,
      snackbar: {
        show: false,
        timeout: 2000,
        type: "success",
        text: "",
      },
      deleteDialog: false,
      handleStatusChange: false,
      selectedTag: null,
      availableTags: [
        { text: "وثائق", value: "documents" },
        { text: "اخبار", value: "news" },
        { text: "بيانات", value: "data" },
        { text: "صور", value: "pictures" },
        { text: "فيديوهات", value: "videos" },
        { text: "منشورات", value: "publications" },
        { text: "بحوث", value: "research" },
        { text: "مقالات", value: "articles" },
      ],
      requiredRule: [(v) => !!v || "This field is required"],
      requiredRule2: [(v) => v.length > 0 || "This field is required"],
    };
  },
  methods: {
    ...mapActions(["editArticle", "deleteArticle"]),
    confirmDelete() {
      this.deleteDialog = true;
    },
    textFormat(item) {
      if (item.name_arabic) {
        if (item.userdirectory) return item.name_arabic;
        else return item.name_arabic;
      }
    },
    async deleteArticleFunc() {
      this.deleteDialog = false;
      try {
        this.loadingSpinner = true;
        await this.deleteArticle(this.articleData);
        this.snackbar.show = true;
        this.snackbar.text = "The article has been deleted successfully";
        this.snackbar.type = "success";
        setTimeout(() => {
          this.snackbar.show = false;
          this.loadingSpinner = false;
          this.closeDialog();
          window.location.reload();
        }, 3000);
      } catch (err) {
        this.loadingSpinner = false;
        this.snackbar.show = true;
        this.snackbar.text = "An error occurred: " + err;
        this.snackbar.type = "error";
      }
    },
    addTag() {
      if (
        this.selectedTag &&
        !this.articleData.tags.includes(this.selectedTag)
      ) {
        this.articleData.tags.push(this.selectedTag);
        this.selectedTag = null;
      }
    },
    removeTag(index) {
      this.articleData.tags.splice(index, 1);
    },
    addNewImage() {
      this.newImages.push({ file: null, type: "" });
    },
    removeNewImage(index) {
      this.newImages.splice(index, 1);
    },
    async editArticleFunc() {
      this.loadingSpinner = true;
      let formData = new FormData();

      // Append each key-value pair of articleData to formData
      for (let key in this.articleData) {
        if (this.articleData.hasOwnProperty(key)) {
          formData.append(key, this.articleData[key]);
        }
      }

      // Append tags
      if (this.articleData.tags) {
        for (let i = 0; i <= this.articleData.tags.length; i++) {
          formData.append(`tags[${i}]`, this.articleData.tags[i]);
        }
      }

      // Append new images
      for (let i = 0; i < this.newImages.length; i++) {
        formData.append(`images[${i}][file]`, this.newImages[i].file);
        formData.append(`images[${i}][type]`, this.newImages[i].type);
      }

      formData.append("_method", "PATCH");
      this.editArticle(formData)
        .then(() => {
          this.snackbar.show = true;
          this.snackbar.text = "The article has been edited successfully";
          this.snackbar.type = "success";
          setTimeout(() => {
            this.snackbar.show = false;
            this.loadingSpinner = false;
            this.closeDialog();
          }, 3000);
        })
        .catch((err) => {
          this.loadingSpinner = false;
          this.snackbar.show = true;
          this.snackbar.text = "An Error occured:" + err;
          this.snackbar.type = "error";
        });
    },
    closeDialog() {
      this.$emit("editArticleDialogClosed", false);
    },
    handleClickOutside(event) {
      const dialog = this.$refs.dialog;
      const button = this.$refs.dialogCard;

      if (
        dialog &&
        button &&
        !dialog.contains(event.target) &&
        !button.contains(event.target)
      ) {
        this.closeDialog();
      }
    },
    updateSnackbar(value) {
      this.snackbar.show = value;
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
    processApprovedLabel(approved) {
      return approved ? "فعال" : "غير فعال";
    },
    toggleApproval() {
      this.approved = !this.approved;
    },
  },
  watch: {
    currentArticle(newArticle) {
      this.articleData = Object.assign({}, newArticle);
    },
  },
};
</script>

<style scoped>
.image-container {
  text-align: center;
}
.green-panel {
  background-color: #a5d6a7;
}
.red-panel {
  background-color: #ef9a9a;
}
.bordered {
  border: 1px solid #ccc;
  padding: 5px;
}
.radio-label {
  margin-right: 10px; /* Adjust this value for the desired spacing */
}
</style>
