import axios from "@/axios";
const state = {
  users: {
    data: [],
  },
};

const getters = {
  allUsersData: (state) => state.users.data,

  getUsers: (state) => (id) => {
    var user = state.users.data.find((user) => user.id === id);
    return user;
  },
};

const actions = {
  async fetchUsers({ commit }) {
    const response = await axios.get("/" + this.getters["getRole"] + "/users");
    commit("SET_USERS", response.data);
  },

  async changeAccountActivation({ commit }, account) {
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/user/" + account.id + "/status",
      account
    );
    if (response.status == 200)
      commit("EDIT_ACCOUNT_STATUS_DATA", response.data.data);
  },

  async addUserFunc({ commit }, info) {
    /*  const response = await axios.post(
      "/" + this.getters["getRole"] + "/signup",
      Product
    ); */
    const response = await axios.post("/administrator/signup", info);
    if (response.status == 200) {
      commit("ADD_USER", response.data.data);
      return "success";
    } else return "failed";
  },

  //editing user info
  async editUser({ commit }, info) {
    const response = await axios.post("/administrator/user/", info);
    if (response.status == 200) {
      commit("EDIT_USER", response.data.data);
      return "success";
    } else return "failed";
  },

  async changePassword(_, info) {
    const response = await axios.post("/authorization/user/password", info);
    if (response.status == 200) {
      return "updated";
    } else return "failed";
  },

  async deleteUser({ commit }, info) {
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/user/" + info.id,
      {
        _method: "delete",
      }
    );
    if (response.status == 204) commit("DELETE_USER", info);
    return response.data;
  },
};
const mutations = {
  SET_USERS: (state, data) => {
    state.users.data = data.data;
  },
  ADD_USER: (state, data) => state.users.data.unshift(data),

  EDIT_ACCOUNT_STATUS_DATA: (state, data) => {
    const i = state.users.data.map((item) => item.id).indexOf(data.id);
    state.users.data[i].status = data.status;
  },

  DELETE_USER: (state, data) => {
    try {
      const i = state.users.data.map((item) => item.id).indexOf(data.id);
      state.users.data.splice(i, 1);
    } catch (error) {
      console.error("Error while committing DELETE_COMPUTATION:", error);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
